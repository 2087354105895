import Footer from "../components/Footer";
import { useMediaQuery } from 'react-responsive';
import { theme } from "../shared/theme";
import WorksBlock from "../components/WorksBlock";
import { useState, useEffect } from "react";
import { getWorks } from "../api/works";

export default function Works() {

  const [works, setWorks] = useState([]);
  useEffect(() => {
    getWorks(setWorks)
  }, []);

  const mockData = [
    {
      // ** 관리자페이지 내 이미지 업로드 시 서버에서 저장하고...(webp 선호) img_url 추가
      "img_url": "https://i.pinimg.com/564x/a5/6e/7f/a56e7fd6bc754ebf7623fa8a0af70e57.jpg",
      // ** 관리자페이지 내 아이콘 업로드 시 서버에서 저장하고...(svg 선호) icon_url 추가
      "icon_url": "https://www.krri.re.kr/cubersc/templete/web/src/img/common/logo_on.png",
      "client": "라인플러스 주식회사",
      "subtitle": "자연어 처리 직무 교육",
      "tags": ["Education", "API"],
      "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nisl tincidunt eget nullam non. Quis hendrerit dolor magna eget est lorem ipsum dolor sit. Volutpat odio facilisis mauris sit amet massa. Commodo odio aenean sed adipiscing diam donec adipiscing tristique. Mi eget mauris pharetra et. Non tellus orci ac auctor augue. Elit at imperdiet dui accumsan sit. Ornare arcu dui vivamus arcu felis. Egestas integer eget aliquet nibh praesent. In hac habitasse platea dictumst quisque sagittis purus. Pulvinar elementum integer enim neque volutpat ac.",
      "date": "2023.03-11" // << 이 정보 넣을건지? => db상에서만, ui에서는 넣지 않음
    },
    {
      "img_url": "https://blog-static.kkday.com/ko/blog/wp-content/uploads/15%EB%B8%94%EB%A1%9C%EA%B7%B8-1.jpg",
      "icon_url": "https://www.krri.re.kr/cubersc/templete/web/src/img/common/logo_on.png",
      "client": "한국철도기술연구원",
      "subtitle": "항만사고 원인/결과 분석을 위한 AI 모델 개발",
      "tags": ["Computer Vision"],
      "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nisl tincidunt eget nullam non. Quis hendrerit dolor magna eget est lorem ipsum dolor sit. Volutpat odio facilisis mauris sit amet massa. Commodo odio aenean sed adipiscing diam donec adipiscing tristique. Mi eget mauris pharetra et. Non tellus orci ac auctor augue. Elit at imperdiet dui accumsan sit. Ornare arcu dui vivamus arcu felis. Egestas integer eget aliquet nibh praesent. In hac habitasse platea dictumst quisque sagittis purus. Pulvinar elementum integer enim neque volutpat ac.",
      "date": "2023.03-11" // << 이 정보 넣을건지?
    },
    {
      "img_url": "https://blog-static.kkday.com/ko/blog/wp-content/uploads/15%EB%B8%94%EB%A1%9C%EA%B7%B8-1.jpg",
      "icon_url": null,
      "client": "한국철도기술연구원",
      "subtitle": "항만사고 원인/결과 분석을 위한 AI 모델 개발",
      "tags": ["Computer Vision"],
      "content": "어쩌구 저쩌구",
      "date": "2023.03-11" // << 이 정보 넣을건지?
    },
    {
      "img_url": "https://blog-static.kkday.com/ko/blog/wp-content/uploads/15%EB%B8%94%EB%A1%9C%EA%B7%B8-1.jpg",
      "icon_url": null,
      "client": "한국철도기술연구원",
      "subtitle": "항만사고 원인/결과 분석을 위한 AI 모델 개발",
      "tags": ["Computer Vision"],
      "content": "어쩌구 저쩌구",
      "date": "2023.03-11" // << 이 정보 넣을건지?
    },
  ]

  const isMobile = useMediaQuery({ maxWidth: theme.size.mobile });

  return (
    <>
      <div className="w-full max-w-6xl mt-[100px]  px-6 mx-auto">
        <div className={` py-10 md:py-20 gap-2 mx-auto  mt-10`}>
          <div className={`mb-4 font-extrabold ${isMobile ? "text-main-banner-mobile" : "text-main-banner-desktop"}`}>
            Works</div>
          <div className={`${isMobile ? "text-lg-title-mobile" : "text-lg-title-desktop"} font-semibold`}>
            <p>매일을 혁신하는 스타트업!</p>
            <p>뉴로플로우의 AI 솔루션을 확인하세요.</p>
          </div>
        </div>
        {/* section */}
        <div className={`${isMobile ? "pb-10 flex flex-col" : "pb-20 grid grid-cols-2"} mx-auto gap-4`}>
          {works && works.map((item, index) => (
            <div key={index}>
              <WorksBlock item={item} />
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  )
}