
export const getNews = async (setNews) => {
  const newsUrl = "/api/news";
  try {
    // apache2 설정파일 ssl 문제로 db 데이터 긁어다 적용(배포 후 풀어야)
    const response = await fetch(newsUrl);
    const data = await response.json();
    // const data = {
    //   "status": 1,
    //   "news": [
    //     {
    //       "id": 3,
    //       "title": "뉴로플로우, 중기부 ‘2024 창업성장기술개발사업 디딤돌 R&D’ 과제 선정",
    //       "img_url": "http://localhost:1998/uploads/1736754027299-847789963.png",
    //       "first_paragraph": "인공지능(AI) 자연어 처리(NLP) 전문기업인 뉴로플로우가 중소벤처기업부와 중소기업기술정보진흥원(TIPA)이 주관하는 ‘2024 창업성장기술개발사업 디딤돌 R&D’ 과제에 최종 선정되었다.",
    //       "src_url": "https://www.sisamagazine.co.kr/news/articleView.html?idxno=508937",
    //       "published_at": "2024-11-26T03:05:00.000Z"
    //     },
    //     {
    //       "id": 5,
    //       "title": "인하대, ‘바이오의약품 원부자재 교육 세미나’ 진행",
    //       "img_url": "http://localhost:1998/uploads/1736754284396-535429427.jpg",
    //       "first_paragraph": "인하대학교(총장 조명우)는 지난 10일 산업통상자원부와 인천광역시가 주최하고 인하대 첨단소재공정연구소가 주관하는 ‘2024 바이오의약품 원부자재 교육 세미나’를 진행했다고 12일 밝혔다.",
    //       "src_url": "https://www.enewstoday.co.kr/news/articleView.html?idxno=2176476",
    //       "published_at": "2024-09-12T08:45:00.000Z"
    //     },
    //     {
    //       "id": 2,
    //       "title": "뉴로플로우, 중소벤처기업부 주관 혁신성장유형 벤처기업 인증 획득",
    //       "img_url": "http://localhost:1998/uploads/1736753989427-618733509.png",
    //       "first_paragraph": "인공지능(AI) 기술 기반의 자연어 처리(NLP) 전문기업인 뉴로플로우 주식회사가 중소벤처기업부가 주관하는 혁신성장유형 벤처기업 인증을 획득했다고 밝혔다.",
    //       "src_url": "https://www.sisamagazine.co.kr/news/articleView.html?idxno=506513",
    //       "published_at": "2024-08-14T08:22:00.000Z"
    //     },
    //     {
    //       "id": 1,
    //       "title": "자연어 처리 연구 기업 뉴로플로우, KOITA 연구개발전담부서 설립 인정서 획득",
    //       "img_url": "http://localhost:1998/uploads/1736753923556-932344412.png",
    //       "first_paragraph": "뉴로플로우 주식회사가 최근 한국산업기술진흥협회(KOITA)로부터 연구개발전담부서 설립 인정서를 받았다고 밝혔다. 뉴로플로우는 개체명 인식, 문서 분류, 챗봇 등 자연어 처리(NLP) 기술에 집중하고 있으며, 이와 관련한 연구 및 개발 활동을 강화할 계획이다.",
    //       "src_url": "https://www.sisamagazine.co.kr/news/articleView.html?idxno=505913",
    //       "published_at": "2024-07-19T00:33:00.000Z"
    //     },
    //     {
    //       "id": 4,
    //       "title": "마약 탐지의 새로운 지평을 열다",
    //       "img_url": "http://localhost:1998/uploads/1736754219547-759077373.jpg",
    //       "first_paragraph": "- AI 후각센서와 딥러닝의 만남으로 획기적으로 높인 탐지율 실현\n - 잃어버린 ‘마약 청정국’ 명예 되찾는 데 이바지할 것\n\n대한민국은 여전히 ‘마약 청정국’일까? 최근 들어 연일 보도되는 각종 마약 사건과 스캔들을 보고 있자면 대한민국은 이제 더 이상 마약 청정국이 아닌 것으로 보인다. 마약 관련 내용을 다룬 영화와 드라마도 쉽게 찾아볼 수 있다. 그동안 일상에서 금기시되던 ‘마약’이라는 소재에 어렵지 않게 접근할 수 있게 된 것이다. 더군다나 최근에는 신종 마약이 젊은 층 사이에서 퍼지며 어두운 그림자가 발끝까지 다가왔다. 마약 청정국의 타이틀, 회복할 수 있을까?\n\n출처 : 이슈메이커(http://www.issuemaker.kr)",
    //       "src_url": "https://www.issuemaker.kr/news/articleView.html?idxno=40449",
    //       "published_at": "2023-09-25T00:00:00.000Z"
    //     }
    //   ]
    // }
    setNews(data.news);
  } catch (error) {
    console.error("Error fetching news:", error);
    throw error;
  }
};

export const addNews = async (news, image, setNews) => {
  const newsUrl = "/api/news";
  try {
    const formData = new FormData();
    formData.append('image', image);
    formData.append('title', news.title);
    formData.append('firstParagraph', news.firstParagraph);
    formData.append('srcUrl', news.srcUrl);
    if (news.publishedAt) formData.append('publishedAt', news.publishedAt);

    const response = await fetch(newsUrl, { method: "POST", body: formData });
    const data = await response.json();
    console.log("data:", data);
    getNews(setNews);
    return data;
  } catch (error) {
    console.error("Error adding news:", error);
    throw error;
  }
};

export const updateNews = async (news, image, setNews) => {
  const newsUrl = `/api/news/${news.id}`;
  try {
    const formData = new FormData();
    if (image) formData.append('image', image);
    if (news.title) formData.append('title', news.title);
    if (news.firstParagraph) formData.append('firstParagraph', news.firstParagraph);
    if (news.srcUrl) formData.append('srcUrl', news.srcUrl);
    if (news.publishedAt) formData.append('publishedAt', news.publishedAt);
    const response = await fetch(newsUrl, { method: "PUT", body: formData });
    const data = await response.json();
    console.log("data:", data);
    getNews(setNews);
    return data;
  } catch (error) {
    console.error("Error updating works:", error);
    throw error;
  }
};

export const deleteNews = async (newsId, setNews) => {
  const newsUrl = `/api/news/${newsId}`;
  try {
    const response = await fetch(newsUrl, { method: "DELETE" });
    const data = await response.json();
    console.log("deleteNews data:", data);
    getNews(setNews);
  } catch (error) {
    console.error("Error deleting news:", error);
    throw error;
  }
};