// import partnerImg from "../assets/01_home/03_partner_bg.jpg"
import { ReactComponent as Partner1 } from "../assets/01_home/03_partner_wateratom.svg"
import { ReactComponent as Partner2 } from "../assets/01_home/03_partner_cheoldo.svg"
import { ReactComponent as Partner3 } from "../assets/01_home/03_partner_seoul.svg"
import { ReactComponent as Partner4 } from "../assets/01_home/03_partner_consumer.svg"
import { ReactComponent as Partner5 } from "../assets/01_home/03_partner_skku.svg"
import Partner6 from "../assets/01_home/03_partner_line.png"
import { ReactComponent as Partner7 } from "../assets/01_home/03_partner_snip.svg"

import { useMediaQuery } from 'react-responsive';

export default function Partners() {
  const isTablet = useMediaQuery({ maxWidth: 1349 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <div className={`${isMobile ? "py-20" : " py-24"} w-screen bg-[#0C062C]`}>
      {/* style={{ backgroundImage: `url(${partnerImg})` }} > */}
      <div className={`${isMobile ? "w-4/5" : "w-3/5"} gap-2 mx-auto`}>
        <div className={`text-primary-purple ${isMobile ? " text-xs" : ""} font-extrabold mb-2 mt-5 text-center`}>Partners</div>
        <div className={`${isMobile ? "text-lg-title-mobile" : "text-lg-title-desktop"}  text-white font-semibold text-center`}>
          <p>다양한 분야의 기업과 기관이 <br />뉴로플로우와 함께 하고 있습니다</p>
        </div>
        <div id="boxWrap" className={` mt-12  gap-6  ${isTablet ? "flex-col flex" : "grid grid-cols-3"}`}>
          <div className="py-4 flex justify-center items-center px-4">
            <Partner1 height={`${isTablet ? "34px" : "44px"}`} />
          </div>
          <div className="py-4 flex justify-center items-center px-4">
            <Partner2 height={`${isTablet ? "38px" : "44px"}`} style={{ marginBottom: isTablet ? "0px" : "16px" }} />
          </div>
          <div className={`py-8 flex justify-center items-center px-4`}>
            <Partner3 height={`${isTablet ? "36px" : "44px"}`} style={{ transform: "translateX(-3%)", marginBottom: isTablet ? "0px" : "16px" }} />
          </div>
        </div>
        <div id="boxWrap" className={` mb-12  gap-6  ${isTablet ? "flex-col flex" : "grid grid-cols-4"}`}>
          <div className="py-8 flex justify-center items-center px-4">
            <Partner4 height={`${isTablet ? "56px" : "60px"}`} style={{ transform: "translateX(-3%)" }} />
          </div>
          <div className=" py-8 flex justify-center items-center px-4">
            <Partner7 height={`${isTablet ? "56px" : "60px"}`} />
          </div>
          <div className=" py-8 flex justify-center items-center px-4">
            <img src={Partner6} style={{ transform: "translateX(-3%)", height: `${isTablet ? "32px" : "42px"}` }} />
          </div>
          {/* 성균관대학교 */}
          <div className=" py-8 flex justify-center items-center px-4">
            <Partner5 height={`${isTablet ? "48px" : "56px"}`} />
          </div>
        </div>
      </div>
    </div>
  )
}
