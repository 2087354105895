import Footer from "../components/Footer";
import { useMediaQuery } from 'react-responsive';
import { theme } from "../shared/theme";
import NewsBlock from "../components/NewsBlock";
import { getNews } from "../api/news";
import { useState, useEffect } from "react";
export default function News() {

  const isTablet = useMediaQuery({ maxWidth: theme.size.tablet });
  const isMobile = useMediaQuery({ maxWidth: theme.size.mobile });
  const [news, setNews] = useState([]);
  useEffect(() => {
    getNews(setNews)
  }, []);

  const mockData = [
    {
      "id": 1,
      "img_url": "https://i.pinimg.com/564x/a5/6e/7f/a56e7fd6bc754ebf7623fa8a0af70e57.jpg",
      "title": "[히든메이커] 마약탐지의 새로운 지평을 열다.",
      "content": {
        "ops": [
          { "insert": "[히든메이커] 마약탐지의 새로운 지평을 열다.\n", "attributes": { "header": 1 } },
          { "insert": "이것은 첫 번째 단락입니다.\n" },
          {
            "insert": { "image": "https://i.pinimg.com/564x/a5/6e/7f/a56e7fd6bc754ebf7623fa8a0af70e57.jpg" }
          },
          { "insert": "이것은 두 번째 단락입니다.\n" }
        ]
      },
      "link": "https://www.naver.com",
      "from": "한겨레",
      "published_at": "2023.03-11"
    },
    {
      "id": 2,
      "img_url": "https://i.pinimg.com/564x/a5/6e/7f/a56e7fd6bc754ebf7623fa8a0af70e57.jpg",
      "title": "[히든메이커] 마약탐지의 새로운 지평을 열다.",
      "content": {
        "ops": [
          { "insert": "이것은 첫 번째 단락입니다.\n" },
          {
            "insert": { "image": "https://example.com/image1.jpg" }
          },
          { "insert": "이것은 두 번째 단락입니다.\n" }
        ]
      },
      "link": "https://www.google.com",
      "from": "한겨레",
      "published_at": "2023.03.11"
    },
    {
      "id": 3,
      "img_url": "https://i.pinimg.com/564x/a5/6e/7f/a56e7fd6bc754ebf7623fa8a0af70e57.jpg",
      "title": "[히든메이커] 마약탐지의 새로운 지평을 열다.",
      "content": {
        "ops": [
          { "insert": "이것은 첫 번째 단락입니다.\n" },
          {
            "insert": { "image": "https://example.com/image1.jpg" }
          },
          { "insert": "이것은 두 번째 단락입니다.\n" }
        ]
      },
      "link": "https://www.google.com",
      "from": "한겨레",
      "published_at": "2023.03-11"
    },
  ]

  return (
    <>
      <div className="w-full max-w-6xl mt-[100px] px-6 mx-auto">
        <div className={` py-10 md:py-20 gap-2 mx-auto  mt-10`}>
          <div className={`mb-4 font-extrabold ${isMobile ? "text-main-banner-mobile" : "text-main-banner-desktop"}`}>
            NEWS</div>
          <div className={`${isMobile ? "text-lg-title-mobile" : "text-lg-title-desktop"} font-semibold`}>
            <p>매일을 혁신하는 스타트업!</p>
            <p>뉴로플로우의 소식을 확인하세요.</p>
          </div>
        </div>
        {/* section */}
        {/* <div className={`${isMobile ? "py-10 flex flex-col" : "py-20 grid grid-cols-2"} mx-auto gap-4`}> */}
        <div className={`grid grid-cols-6 mx-auto gap-4 pb-40`}>
          {news && news.map((item, index) => {
            if (!isMobile) {
              if (index === 0) {
                // 첫 번째 아이템: 한 행 전체 차지
                return (
                  <div key={index} className="col-span-full p-4">
                    <NewsBlock item={item} section={0} />
                  </div>
                )

              } else if (index > 0 && index <= 4) {
                // 2번째부터 5번째까지: 2열 2행
                return (
                  <div key={index} className="col-span-3 p-4">
                    <NewsBlock item={item} section={1} />
                  </div>
                )
              } else {
                // 나머지: 3열
                return (
                  <div key={index} className="col-span-2 p-4">
                    <NewsBlock item={item} section={2} />
                  </div>
                )
              }
            } else {
              // mobile 
              return (
                <div key={index} className="col-span-full">
                  <NewsBlock item={item} section={1} />
                </div>
              )
            }
          })}
        </div>
      </div>
      <Footer />
    </>
  )
}