import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css'; // Bubble 테마
import './quil.css';

export default function QuillViewer({ content }) {

  if (!content) return <p>Loading...</p>;

  return (
    <div>
      <ReactQuill value={content} modules={{
        toolbar: false, // 툴바 숨김 (읽기 전용 예제)
      }} readOnly={true} theme="bubble" />
    </div>
  );
};

