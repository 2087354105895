import { useEffect, useState } from "react";


export default function LoginCheck({
  children,
}) {
  function generateRandomString() {
    const characters = '@#$%^&*ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';

    for (let i = 0; i < 12; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }

    return result;
  }
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [inputPassword, setInputPassword] = useState("");
  const [sessionKey, setSessionKey] = useState("");
  const [sessionValue, setSessionValue] = useState("");

  const handleLogin = () => {
    if (inputPassword === process.env.REACT_APP_ACCESS_PASSWORD) {
      sessionStorage.setItem(sessionKey, sessionValue);
      setIsLoggedIn(true);
    } else {
      alert("암호가 틀립니다");
    }
  };
  useEffect(() => {
    const key = generateRandomString();
    const value = generateRandomString();
    setSessionKey(key);
    setSessionValue(value);
    const loginStatus = sessionStorage.getItem(sessionKey);
    setIsLoggedIn(loginStatus === sessionValue);
  }, []);

  if (isLoggedIn) {
    return (
      <div className="login-prompt">
        {children}
      </div>
    );
  } else {
    return (
      <div className="w-screen h-screen">
        <div className="max-w-6xl mt-36 dpx-16 flex flex-col gap-10 items-center mx-auto">
          <p className={`text-2xl font-bold`}>관리자 로그인</p>
          <p className={`text-xl text-[#aaa]`}>*관리자만 접속가능합니다.</p>
          <div className="w-2/5 flex gap-2 justify-center items-center">
            <input
              className="flex border w-3/5 h-10 px-2 rounded-md focus:outline-primary-purple/85"
              type="password"
              placeholder="암호를 입력하세요"
              value={inputPassword}
              onKeyDown={(e) => {
                if (e.key === "Enter" && inputPassword !== "") {
                  handleLogin();
                }
              }}
              onChange={(e) => setInputPassword(e.target.value)}
            />
            <button className="rounded-md w-1/5 h-10 bg-primary-purple/85 text-white" onClick={inputPassword !== "" ? handleLogin : null} >로그인</button>
          </div>
        </div>
      </div>

    )
  }
}