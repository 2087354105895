import React, { useEffect, useState } from 'react';
import LoginCheck from '../components/LoginCheck';
import WorksAddModal from '../components/WorksAddModal';
import NewsAddModal from '../components/NewsAddModal';
import { getWorks, deleteWorks } from '../api/works';
import { getNews, deleteNews } from '../api/news';

export default function Admin() {
  const [currentMenu, setCurrentMenu] = useState("works");
  const [works, setWorks] = useState([]);
  const [news, setNews] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isNewsModalOpen, setIsNewsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("add");

  const [item, setItem] = useState(null);
  const [newsItem, setNewsItem] = useState(null);

  useEffect(() => {
    getWorks(setWorks);
    getNews(setNews);
  }, []);



  return <LoginCheck>
    <div className="w-screen h-screen text-[#666666]">
      {isModalOpen && <WorksAddModal setIsModalOpen={setIsModalOpen} modalMode={modalMode} item={item} setWorks={setWorks} />}
      {isNewsModalOpen && <NewsAddModal setIsModalOpen={setIsNewsModalOpen} modalMode={modalMode} item={newsItem} setNews={setNews} />}
      <div className="max-w-6xl mt-36 px-16 mx-auto">
        <p className={`text-2xl font-bold pb-10 `}>관리자 메뉴</p>
        <div className="flex gap-8 items-center">
          <p className={`cursor-pointer text-lg font-semibold mb-4 py-1 px-2 border  rounded-lg ${currentMenu === "works" ? "text-primary-purple border-primary-purple" : "text-gray-300 border-gray-300"}`} onClick={() => setCurrentMenu("works")}>Works</p>
          <p className={`cursor-pointer text-lg font-semibold mb-4 py-1 px-2 border  rounded-lg ${currentMenu === "news" ? "text-primary-purple border-primary-purple" : "text-gray-300 border-gray-300"}`} onClick={() => setCurrentMenu("news")}>News</p>
        </div>
        {/* works */}
        {currentMenu === "works" && (
          <div className="w-full pb-56">
            <p className="text-xl font-bold pb-4">Works 목록</p>
            <div className="flex justify-between items-center pr-8">
              <p className="text-sm text-gray-300">Total {works.length}</p>
              <p className="cursor-pointer px-4 py-1 w-fit h-fit text-white bg-primary-purple/85 border border-primary-purple rounded-lg"
                onClick={() => {
                  setIsModalOpen(true);
                  setModalMode("add");
                }}>추가</p>
            </div>
            <div className="w-full flex flex-col gap-4 pt-4 border-t mt-4">
              {works.map((work) => (
                <div key={work.id} className="flex py-2 justify-between pr-8">
                  <div className="flex gap-2">
                    <img src={work.img_url} alt={work.client} className="aspect-[4/3] h-56 rounded-lg object-cover" />
                    <div className="flex flex-col gap-3 px-8">
                      <p className="text-lg font-semibold">{work.client}</p>
                      <div className="flex gap-2">
                        {work.tags.map((item) => (
                          <p className={`text-sm font-semibold px-2.5 py-1 w-fit block bg-gray-200 bg-opacity-60 rounded-full text-gray-600`}>
                            {item.tag}
                          </p>
                        ))}
                      </div>
                      <p className="text-lg">{work.content}</p>
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    <p className="cursor-pointer px-4 py-1 h-fit text-primary-purple border border-primary-purple rounded-lg"
                      onClick={() => {
                        setIsModalOpen(true);
                        setModalMode("update");
                        setItem(work);
                      }}>수정</p>
                    <p className="cursor-pointer px-4 py-1 h-fit text-primary-purple border border-primary-purple rounded-lg"
                      onClick={() => {
                        window.confirm("삭제하시겠습니까?") && deleteWorks(work.id, setWorks);
                      }}>삭제</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {/* news */}
        {currentMenu === "news" && (
          <div className="w-full pb-56">
            <p className="text-xl font-bold pb-4">News 목록</p>
            <div className="flex justify-between items-center pr-8">
              <p className="text-sm text-gray-300">Total {news.length}</p>
              <p className="cursor-pointer px-4 py-1 w-fit h-fit bg-primary-purple/85 text-white border border-primary-purple rounded-lg"
                onClick={() => {
                  setIsNewsModalOpen(true);
                  setModalMode("add");
                }}>추가</p>
            </div>
            <div className="w-full flex flex-col gap-4 pt-4 border-t mt-4">
              {news.map((news) => (
                <div key={news.id} className=" flex py-2 justify-between pr-8" >
                  <div className="cursor-pointer flex gap-2 w-[90%]" onClick={() => window.open(news.src_url, "_blank")}>
                    <img src={news.img_url} alt={news.title} className="aspect-[4/3] h-56 rounded-lg object-cover" />
                    <div className="flex flex-col gap-2 px-4 text-lg">
                      <p className="text-lg font-semibold">{news.title}</p>
                      <p>{news.first_paragraph.length > 200 ? news.first_paragraph.slice(0, 200) + "..." : news.first_paragraph}</p>
                      <p className="text-gray-300 mt-auto mb-4">{news.published_at.slice(0, 10)}</p>
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    <p className="cursor-pointer px-4 py-1 h-fit text-primary-purple border border-primary-purple rounded-lg"
                      onClick={() => {
                        setIsNewsModalOpen(true);
                        setModalMode("update");
                        setNewsItem(news);
                      }}>수정</p>
                    <p className="cursor-pointer px-4 py-1 h-fit text-primary-purple border border-primary-purple rounded-lg"
                      onClick={() => {
                        window.confirm("삭제하시겠습니까?") && deleteNews(news.id, setNews);
                      }}>삭제</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  </LoginCheck>;
}
