import { addNews, updateNews } from "../api/news"
import { useState, useEffect } from "react";
import { getNews } from "../api/news";
// 추가, 수정 모달
//  반응형 적용 X
export default function NewsAddModal({ setIsModalOpen, modalMode, item, setNews }) {
  const [title, setTitle] = useState(modalMode === "add" ? "" : item.title);
  const [firstParagraph, setFirstParagraph] = useState(modalMode === "add" ? "" : item.first_paragraph);
  const [image, setImage] = useState(modalMode === "add" ? null : item.img_url);
  const [srcUrl, setSrcUrl] = useState(modalMode === "add" ? "" : item.src_url);
  const [publishedAt, setPublishedAt] = useState(modalMode === "add" ? null : convertFromISO(item.published_at));
  const [isValid, setIsValid] = useState(true);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setPublishedAt(value);

    // 정규식으로 형식 검증
    const datetimeRegex = /^\d{4}-\d{2}-\d{2}$/;
    setIsValid(datetimeRegex.test(value));
  };
  useEffect(() => {
    if (modalMode === "update") {
      setTitle(item.title);
      setFirstParagraph(item.first_paragraph);
      setImage(item.img_url);
      setSrcUrl(item.src_url);
      setPublishedAt(convertFromISO(item.published_at).split(" ")[0]);
    }
  }, [modalMode, item]);

  function convertFromISO(isoString) {
    const date = new Date(isoString);

    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const day = String(date.getUTCDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  return (
    <div className={`w-full h-full fixed top-0 left-0 bg-black/50 z-50 text-[#666666]`}>
      <div className={`fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 border bg-white w-[40vw]  h-auto shadow-sm px-8 py-6`}>
        <div className="flex flex-col gap-4 items-center">
          <div className="flex items-center ">
            <p className="w-20">제목</p>
            <textarea type="text" placeholder="ex) 뉴로플로우, 구글에 인수되다 " value={title}
              rows={2}
              className="w-96 resize-none border border-gray-300 rounded-md p-2 placeholder:text-gray-300 focus:outline-primary-purple/85"
              onChange={(e) => setTitle(e.target.value)} />
          </div>
          <div className="flex items-center">
            <p className="w-20">첫 문단</p>
            <textarea type="text" placeholder="ex) 판교에서 가장 큰 회사인 뉴로플로우 주식회사가 구글에 인수되었습니다. 포브스 선정 젠슨황이 구매 희망한 회사 1위..." value={firstParagraph}
              rows={3}
              className="w-96 resize-none border border-gray-300 rounded-md p-2 placeholder:text-gray-300 focus:outline-primary-purple/85"
              onChange={(e) => setFirstParagraph(e.target.value)} />
          </div>
          <div className="flex items-center ">
            <p className="w-20">이미지</p>
            <div
              onDrop={(e) => {
                e.preventDefault();
                const file = e.dataTransfer.files[0];
                if (file && (file.type === 'image/webp' || file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg')) {
                  setImage(file);
                } else {
                  setImage(null);
                }
              }}
              onDragOver={(e) => e.preventDefault()}
              className="text-sm text-[#aaa] flex justify-center items-center w-96 aspect-[3/2] border-gray-300 border-dashed border-2 rounded-md"
            >
              <input
                className="input-file"
                type="file"
                accept=".webp,.png, .jpg, .jpeg"
                onChange={(e) => setImage(e.target.files[0])}
              />
              {
                image ? <img src={item && item.img_url === image ? image : URL.createObjectURL(image)} alt="works" className="w-full h-full object-cover rounded-md" /> :
                  "선택한 파일이 없습니다."
              }
            </div>
          </div>
          <div className="flex items-center">
            <p className="w-20">원본 링크</p>
            <input type="text" placeholder="ex) https://www.news.com/1234567890" value={srcUrl}
              className="w-96 border border-gray-300 rounded-md p-2 placeholder:text-gray-300 focus:outline-primary-purple/85"
              onChange={(e) => setSrcUrl(e.target.value)} />
          </div>
          <div className="flex items-center">
            <p className={`w-20 ${publishedAt ? "pb-4" : ""}`}>등록 시간</p>
            <div className="flex flex-col">
              <input type="text" placeholder="YYYY-MM-DD" value={publishedAt}
                className="w-96 border border-gray-300 rounded-md p-2 placeholder:text-gray-300 focus:outline-primary-purple/85"
                onChange={(e) => handleInputChange(e)} />
              <p className={`text-sm  ${!isValid ? "text-red-400" : "text-[#ccc]"}`}>{!isValid ? "YYYY-MM-DD 형식을 맞춰주세요" : "*생략 시 현재 시간으로 등록"}</p>
            </div>
          </div>
          <div className="flex w-[464px] justify-end gap-2 my-4">
            <button className="border-gray-400 border text-gray-400 px-4 py-2 rounded-md" onClick={() => setIsModalOpen(false)}>취소</button>
            <button className="border-primary-purple border bg-primary-purple/85 text-white px-4 py-2 rounded-md"
              onClick={() => {
                if (modalMode === "add") {
                  if (!title || !firstParagraph || !image || !srcUrl) {
                    alert("모든 필드를 입력해주세요.");
                    return;
                  }
                  if (!isValid) {
                    alert("YYYY-MM-DD 형식을 맞춰주세요");
                    return;
                  }
                  addNews({ title: title, firstParagraph: firstParagraph, srcUrl: srcUrl, publishedAt: publishedAt }, image, setNews);
                }
                else {
                  updateNews({
                    id: item.id,
                    title: title,
                    firstParagraph: firstParagraph,
                    srcUrl: srcUrl,
                    publishedAt: publishedAt,
                  }, image, setNews);
                }
                setIsModalOpen(false);
              }}>{modalMode === "add" ? "등록" : "수정"}</button>
          </div>
        </div>
      </div>
    </div>
  )
}