import Whoweare from "../about/Whoweare";
import History from "../about/History";
import Footer from "../components/Footer";
import { useMediaQuery } from 'react-responsive';
import { theme } from "../shared/theme";

export default function About() {
  const isTablet = useMediaQuery({ maxWidth: theme.size.tablet });
  const isMobile = useMediaQuery({ maxWidth: theme.size.mobile });

  return (
    <>
      <div className="mt-[100px]">
        <div className={`w-full max-w-6xl py-10 md:py-20 gap-2 mx-auto  mt-10 px-6`}>
          <div className={`mb-4 font-extrabold ${isMobile ? "text-main-banner-mobile" : "text-main-banner-desktop"}`}>
            About</div>
          <div className={`${isMobile ? "text-lg-title-mobile" : "text-lg-title-desktop"} font-semibold`}>
            <p>
              사회 친화적 딥테크 기업, </p><p>
              뉴로플로우를 소개합니다
            </p>
          </div>
        </div>
        <Whoweare />
        {/* history 내부에 ceomini 있음(같은 img 사용으로 내부에 있음) */}
        <History />
      </div>
      <Footer />
    </>
  )
}