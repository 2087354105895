import nlpImg from "../assets/03_solution/nlp/01_nlp_bg.webp"
import nlpImgMobile from "../assets/03_solution/nlp/01_nlp_bg_mobile.webp"
import { ReactComponent as DocIcon } from "../assets/03_solution/nlp/02_doc_icon.svg"
import doc1 from "../assets/03_solution/nlp/02_doc1.webp"
import doc2 from "../assets/03_solution/nlp/02_doc2.webp"
import { ReactComponent as EntityIcon } from "../assets/03_solution/nlp/03_entity_icon.svg"
import entity1 from "../assets/03_solution/nlp/03_entity1.webp"
import entity2 from "../assets/03_solution/nlp/03_entity2.webp"
import { ReactComponent as SimIcon } from "../assets/03_solution/nlp/04_sim_icon.svg"
import sim1 from "../assets/03_solution/nlp/04_sim1.webp"
import sim2 from "../assets/03_solution/nlp/04_sim2.webp"
import { useMediaQuery } from 'react-responsive';

export default function Nlp() {
  const isTablet = useMediaQuery({ maxWidth: 1349 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <>
      <div className={`relative w-screen ${isMobile ? "h-[320px]" : " h-[400px]"} mx-auto bg-no-repeat bg-cover bg-center py-14`} style={{ backgroundImage: `url(${isTablet ? nlpImgMobile : nlpImg})` }}>
      <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-30  py-14">
        <div className={`w-full max-w-6xl  px-6 mx-auto`}>
          <div className={`text-[#6F6F6F] ${isMobile ? " text-xs" : ""} font-extrabold mb-2 mt-5`}>NLP</div>
          <div className={`${isMobile ? "text-lg-title-mobile" : "text-lg-title-desktop"}  text-[#D1C5F4] font-semibold`}>자연어처리</div>
          <div className={`text-white ${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>
            <p>Natural Language Processing, NLP</p>
            <p>인간의 언어를 해석 및 조작하는 능력을 </p>
            <p>컴퓨터에 부여하는 기계 학습 기술</p>
          </div>
        </div>
        </div>
      </div>
      <div className="w-full max-w-6xl  px-6 mx-auto">
        <div id="part1" className={`${isMobile ? "pt-20 pb-12" : "py-20"}`}>
          <div className="flex justify-center">
            <DocIcon height={isMobile ? "40px" : "60px"} /> </div>
          <div className={`${isMobile ? "text-lg-title-mobile" : "text-lg-title-desktop"} font-semibold  mb-3 text-center mt-2`}>
            문서분류
          </div>
          <div className={`text-center ${isMobile ? "text-desc-tablet" : "text-desc-desktop"} pb-12`}>
            {
              isMobile ? <>
                <p>텍스트 데이터를 특정 범주, 카테고리로 분류</p>
                <p>주어진 문서가 어떤 주제에 속하거나 </p>
                <p>어떤 유형의 정보를 포함하는지를 판별</p>
              </> :
                <>
                  <p>텍스트 데이터를 특정 범주 또는 카테고리로 분류</p>
                  <p>주어진 문서가 어떤 주제에 속하거나 어떤 유형의 정보를 포함하는지를 </p>
                  <p>판별하는 데 주로 사용됨</p>
                </>
            }
          </div>
          <div id="box-wrap1" className={`flex mb-16 justify-center items-center gap-8 ${isTablet ? " flex-col" : ""}`}>
            <div className={`w-full md:w-1/2 px-3  py-7 flex flex-col justify-center  rounded-lg`} >
              <div className={`text-center ${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>활용예시</div>
              <div id="subtitle1-1" className={`text-center font-semibold ${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>감성분류</div>
              <div className={`flex justify-center rounded-lg my-4`}>
                <img src={doc1} style={{ borderRadius: "10px" }} />
              </div>
              <div className={`text-center ${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>
                <p> 텍스트나 문서를 감정 카테고리로 분류. </p>
                <p> 제품 리뷰, 소셜 미디어 포스트, 고객 피드백 등에서 사용 </p>
              </div>
            </div>
            <div className={`w-full md:w-1/2 px-3  py-7 flex flex-col justify-center  rounded-lg`} >
              <div className={`text-center ${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>활용예시</div>
              <div id="subtitle1-2" className={`text-center font-semibold ${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>소비자 불만 유형 분류</div>
              <div className={`flex justify-center rounded-lg my-4`}>
                <img src={doc2} style={{ borderRadius: "10px" }} />
              </div>
              <div className={`text-center ${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>
                <div> 불만사항을 특정 범주 또는 카테고리로 분류. </div>
                <div> 제품, 서비스, 가격, 배송 등과 관련된 불만을 식별 및 분류하는 데 사용 </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div data-id="divider" className={`bg-[#EEEEEE] w-full h-8`} />
      <div className="w-full max-w-6xl  px-6 mx-auto">
        <div id="part2" className={`${isMobile ? "pt-20 pb-12" : "py-20"}`}>
          <div className="flex justify-center"> <EntityIcon height={isMobile ? "40px" : "60px"} /> </div>
          <div className={`${isMobile ? "text-lg-title-mobile" : "text-lg-title-desktop"} font-semibold  mb-3 text-center mt-2`}>
            개체명 인식
          </div>
          <div className={`text-center ${isMobile ? "text-desc-tablet" : "text-desc-desktop"} pb-12`}>
            <p>텍스트 데이터에서 사람 이름, 장소, 날짜 등과 같은</p>
            <p>중요한 개체명을 식별하고 특정 카테고리로 분류</p>
          </div>
          <div id="box-wrap2" className={`flex mb-16 justify-center items-center gap-8 ${isTablet ? " flex-col" : ""}`}>
            <div className={`w-full md:w-1/2 px-3  py-7 flex flex-col justify-center  rounded-lg`} >
              <div className={`text-center ${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>
                활용예시</div>
              <div id="subtitle2-1" className={`text-center font-semibold ${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>
                개인정보 식별 및 마스킹</div>
              <div className={`flex justify-center rounded-lg my-4`}>
                <img src={entity1} style={{ borderRadius: "10px" }} />
              </div>
              <div className={`text-center ${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>
                {isMobile ? <>
                  <p>개인 정보 같은 중요 개체 식별, 마스킹, 숨김 처리</p>
                  <p>민감한 정보들을 보호하기 위해 사용</p>
                </> :
                  <>
                    <p>개인 정보와 같은 중요 개체를 식별, 마스킹 또는 숨김 처리</p>
                    <p>개인 정보와 관련된 민감 정보를 보호하기 위해 사용</p>
                  </>}
              </div>
            </div>
            <div className={`w-full md:w-1/2 px-3  py-7 flex flex-col justify-center  rounded-lg`} >
              <div className={`text-center ${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>활용예시</div>
              <div id="subtitle2-2" className={`text-center font-semibold ${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>사고 관련 개체 찾기</div>
              <div className={`flex justify-center rounded-lg my-4`}>
                <img src={entity2} style={{ borderRadius: "10px" }} />
              </div>
              <div className={`text-center ${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>
                보험 관련 문서나 사고 보고서와 같이 사고와 관련된 정보를 추출하거나 분류하는 데 사용
              </div>
            </div>
          </div>
        </div>
        </div>
        <div data-id="divider" className={`bg-[#EEEEEE] w-full h-8`} />
        <div className="w-full max-w-6xl  px-6 mx-auto">
        <div id="part3" className={`${isMobile ? "pt-20 pb-12" : "py-20"}`}>
          <div className="flex justify-center">
            <SimIcon height={isMobile ? "40px" : "60px"} /> </div>
          <div className={`${isMobile ? "text-lg-title-mobile" : "text-lg-title-desktop"} font-semibold  mb-3 text-center mt-2`}>
            문서 유사도
          </div>
          <div className={`text-center ${isMobile ? "text-desc-tablet" : "text-desc-desktop"} pb-12`}>
            {
              isMobile ?
                <>
                  <p> 두개 이상의 문서 간의 유사성을 측정하는 작업</p>
                  <p> 문서 간 관련성 분석, 검색 결과의 순위 설정 등 응용 </p>
                </>
                :
                <>
                  <p> 문서 유사도는 두개 이상의 문서 간의 유사성을 측정하는 작업</p>
                  <p> 문서 간 관련성 분석, 검색 결과의 순위 설정 등 다양한 응용 분야에서 활용 </p>
                </>
            }</div>
          <div id="box-wrap3" className={`flex mb-16 pb-32 justify-center items-center gap-8 ${isTablet ? " flex-col" : ""}`}>
            <div className={`w-full md:w-1/2 px-3  py-7 flex flex-col justify-center  rounded-lg`} >
              <div className={`text-center ${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>
                활용예시</div>
              <div id="subtitle3-1" className={`text-center font-semibold ${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>
                유사 판례 추천</div>
              <div className={`flex justify-center rounded-lg my-4`}>
                <img src={sim1} style={{ borderRadius: "10px" }} />
              </div>
              <div className={`text-center ${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>
                문서 유사도를 활용하여 법률 분야에서 유사한 판례(법적 결정 사례)를 찾아내고 추천.
              </div>
            </div>
            <div className={`w-full md:w-1/2 px-3  py-7 flex flex-col justify-center  rounded-lg`} >
              <div className={`text-center ${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>
                활용예시</div>
              <div id="subtitle3-2" className={`text-center font-semibold ${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>
                유사 특허 추천</div>
              <div className={`flex justify-center rounded-lg my-4`}>
                <img src={sim2} style={{ borderRadius: "10px" }} />
              </div>
              <div className={`text-center ${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>
                {isMobile ? <>
                  <p>특허 분야 내 유사한 특허 문서를 추천. </p>
                  <p>이미 존재하는 특허 문서와 유사한 기술 </p>
                  <p>또는 발명을 탐색, 특허 출원 전에 중복성 확인</p>
                </> :
                  <>
                    <p>특허 관련 분야에서 유사한 특허 문서를 추천하는 작업 </p>
                    <p>이미 존재하는 특허 문서와 유사한 기술 또는 발명을 </p>
                    <p> 탐색하여 특허 출원 전에 중복성을 확인하는 데 도움을 줌.</p>
                  </>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}