import { useEffect, useState } from "react";
import Nlp from "../solution/Nlp";
import Footer from "../components/Footer";
import { useMediaQuery } from 'react-responsive';
import { useNavigate, useLocation } from "react-router-dom";
import { theme } from "../shared/theme";
import Cv from "../solution/Cv";
export default function Solution() {
  const isTablet = useMediaQuery({ maxWidth: theme.size.tablet });
  const isMobile = useMediaQuery({ maxWidth: theme.size.mobile });
  const navigate = useNavigate();
  const { pathname } = useLocation(); // 현재 경로 가져오기
  const [curTab, setCurTab] = useState(pathname.split('/').pop());
  useEffect(() => {
    setCurTab(pathname.split('/').pop())
  }, [pathname])
  return (
    <>
      <div className="w-screen">
        <div className={`w-full max-w-6xl mt-[100px]  px-6 mx-auto pt-20 pb-5`}>
          <div className={`mb-4 font-extrabold ${isMobile ? "text-main-banner-mobile" : "text-main-banner-desktop"}`}>Solution</div>
          <div className={`${isMobile ? "text-lg-title-mobile" : "text-lg-title-desktop"} font-semibold`}>
            <p>더 좋은 사회를 위한 솔루션,</p>
            <p>뉴로플로우를 통해 만나보세요</p>
          </div>
          <div className={`flex gap-10 mt-12`}>
            <div id="tab_nlp" className={`flex relative ${isTablet ? "text-desc-tablet" : "text-desc-desktop"} py-[10px] cursor-pointer w-28 font-semibold justify-center ${curTab === "nlp" ? "text-primary-purple" : ""}`} onClick={() => navigate("/service/solution/nlp")}>자연어 처리
              <div id="dot_nlp" className={`absolute -bottom-[3px] left-[50%] w-[6px] h-[6px] rounded-full ${curTab === "nlp" ? "bg-primary-purple" : "bg-transparent"}`} style={{ transform: "translateX(-50%)" }} />
            </div>
            <div id="tab_cv" className={`flex relative ${isTablet ? "text-desc-tablet" : "text-desc-desktop"} py-[10px] cursor-pointer w-28 font-semibold justify-center ${curTab === "cv" ? "text-primary-purple" : ""}`} onClick={() => navigate("/service/solution/cv")}>컴퓨터 비전
              <div id="dot_cv" className={`absolute -bottom-[3px] left-[50%] w-[6px] h-[6px] rounded-full ${curTab === "cv" ? "bg-primary-purple" : "bg-transparent"}`} style={{ transform: "translateX(-50%)" }} />
            </div>
          </div>
        </div>
        {curTab === "nlp" ? <Nlp /> : <Cv />}
      </div>
      <Footer />
    </>
  )
}