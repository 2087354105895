import historyImg from "../assets/02_about/02_history_bg.webp"
import CeoMini from "./CeoMini";
import { useMediaQuery } from 'react-responsive';
import { theme } from "../shared/theme";
export default function History() {
  const isMobile = useMediaQuery({ maxWidth: theme.size.mobile });

  return (
    <>
      <div className="pt-8 pb-32 md:pt-24 md:pb-48 mx-auto bg-cover bg-no-repeat " style={{ backgroundImage: `url(${historyImg})` }}>
        <div className={`w-full max-w-6xl mx-auto pb-10 px-6`}>
          <div className={`text-primary-purple text-xs md:text-base font-extrabold mb-2 mt-5`}>History</div>
          {isMobile ?
            <div className="text-white text-lg-title-desktop font-semibold h-[700px]">
              <div className={`text-lg-title-mobile  text-white font-semibold`}> 연혁 </div>
              <div className="flex ">
                <div className="ml-1 h-[700px] w-[4px] mt-7 relative" style={{ background: 'linear-gradient(transparent, #909090, transparent)' }}>
                  <div className="absolute w-[90vw] h-full flex flex-col justify-evenly">
                    <div className="flex">
                      <div id="dot1" className="mt-6 w-[10px] h-[10px] rounded-full border-[#8264E1] border-[2px] " style={{ transform: 'translateX(-30%)' }} />
                      <div className="ml-4 flex flex-col">
                        <div className="text-lg-title-mobile font-semibold mt-3">2022 </div>
                        <ul>
                          <li className="text-desc-mobile my-[5px] py-[3px] md:translate-x-[5%]" >한국철도기술연구원 프로젝트 수주</li>
                          <li className="text-desc-mobile my-[5px] py-[3px] md:translate-x-[5%]" >한국수력원자력 중앙연구원 프로젝트 수주</li>
                        </ul>
                      </div>
                    </div>
                    <div className="flex">
                      <div id="dot2" className="mt-6 w-[10px] h-[10px] rounded-full border-[#8264E1] border-[2px] " style={{ transform: 'translateX(-30%)' }} />
                      <div className="ml-4 flex flex-col">
                        <div className="text-lg-title-mobile font-semibold mt-3">2023
                        </div>
                        <ul>    
                          <li className="text-desc-mobile my-[5px] py-[3px] md:translate-x-[5%] break-keep" >성균관대 캠퍼스타운 킹고인 입주경진대회 선정</li>
                          <li className="text-desc-mobile my-[5px] py-[3px] md:translate-x-[5%]" >한국철도기술연구원 프로젝트 수주</li>
                          <li className="text-desc-mobile my-[5px] py-[3px] md:translate-x-[5%]" >서울시청 프로젝트 수주</li>
                          <li className="text-desc-mobile my-[5px] py-[3px] md:translate-x-[5%]" >청년창업사관학교 13기 선정</li>
                          <li className="text-desc-mobile my-[5px] py-[3px] md:translate-x-[5%]" >현대자동차 정몽구재단 H-온드림 11기 선정 </li>
                        </ul>
                      </div>
                    </div>
                    <div className="flex">
                      <div id="dot3" className="mt-6 w-[10px] h-[10px] rounded-full border-[#8264E1] border-[2px] " style={{ transform: 'translateX(-30%)' }} />
                      <div className="ml-4 flex flex-col">
                        <div className="text-lg-title-mobile font-semibold mt-3">2024
                        </div>
                        <ul className="">
                          <li className="text-desc-mobile my-[5px] py-[3px] md:translate-x-[5%]" >산업 맞춤형 혁신 바우처 공급기업 선정</li>
                          <li className="text-desc-mobile my-[5px] py-[3px] md:translate-x-[5%]" >한국철도기술연구원 프로젝트 수주</li>
                          <li className="text-desc-mobile my-[5px] py-[3px] md:translate-x-[5%]" >중소기업 스마트 서비스 공급기업 선정</li>
                          <li className="text-desc-mobile my-[5px] py-[3px] md:translate-x-[5%]" >성남창업센터(정글ON) 입주기업 선정</li>
                          <li className="text-desc-mobile my-[5px] py-[3px] md:translate-x-[5%]" >혁신성장유형 벤처기업인증</li>
                          <li className="text-desc-mobile my-[5px] py-[3px] md:translate-x-[5%]" >창업성장기술개발사업 디딤돌 R&D 선정  </li>
                          <li className="text-desc-mobile my-[5px] py-[3px] md:translate-x-[5%] break-keep" >K-ICT 클라우드 혁신센터 지원사업 성과 우수기업 선정  </li>
                        </ul>
                      </div>
                    </div>
                    <div className="flex">
                      <div id="dot4" className="mt-6 w-[10px] h-[10px] rounded-full border-[#8264E1] border-[2px] " style={{ transform: 'translateX(-30%)' }} />
                      <div className="ml-4 flex flex-col">
                        <div className="text-lg-title-mobile font-semibold mt-3 w-10">현재
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> :
            <div className="flex flex-col gap-10 text-white text-lg-title-desktop font-semibold h-[540px]">
              <div className={`text-lg-title-desktop text-white font-semibold`}> 연혁 </div>
              <div className="h-[2px] w-full mt-7 relative" style={{ background: 'linear-gradient(to right, transparent, #909090, transparent)' }}>
                <div className="absolute w-full flex justify-evenly">
                  <div className="flex flex-col items-center">
                    <div id="dot1" className=" w-[10px] h-[10px] rounded-full border-[#8264E1] border-[2px] " style={{ transform: 'translateX(-50%) translateY(-40%)' }} />
                    <div className="">
                      <div className="text-xl font-semibold mt-3 text-center">2022 </div>
                      <ul>
                        <li className=" text-base my-[5px] py-[3px]" style={{ transform: 'translateX(10%)' }}>한국철도기술연구원 프로젝트 수주</li>
                        <li className=" text-base my-[5px] py-[3px]" style={{ transform: 'translateX(10%)' }}>한국수력원자력 중앙연구원 프로젝트 수주</li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col items-center">
                    <div id="dot2" className="w-[10px] h-[10px] rounded-full border-[#8264E1] border-[2px] " style={{ transform: 'translateX(-50%) translateY(-40%)' }} />
                    <div className="">
                      <div className="text-xl font-semibold mt-3 text-center">2023
                      </div>
                      <ul>
                        <li className=" text-base my-[5px] py-[3px]" style={{ transform: 'translateX(10%)' }}>성균관대 캠퍼스타운 킹고인 입주경진대회 선정</li>
                        <li className=" text-base my-[5px] py-[3px]" style={{ transform: 'translateX(10%)' }}>한국철도기술연구원 프로젝트 수주</li>
                        <li className=" text-base my-[5px] py-[3px]" style={{ transform: 'translateX(10%)' }}>서울시청 프로젝트 수주</li>
                        <li className=" text-base my-[5px] py-[3px]" style={{ transform: 'translateX(10%)' }}>청년창업사관학교 13기 선정</li>
                        <li className=" text-base my-[5px] py-[3px]" style={{ transform: 'translateX(10%)' }}>현대자동차 정몽구재단 H-온드림 11기 선정 </li>
                      </ul>
                    </div>
                  </div>
                  <div className="flex flex-col items-center">
                    <div id="dot3" className="w-[10px] h-[10px] rounded-full border-[#8264E1] border-[2px] " style={{ transform: 'translateX(-50%) translateY(-40%)' }} />
                    <div className="">
                      <div className="text-xl font-semibold mt-3 text-center">2024
                      </div>
                      <ul>
                        <li className=" text-base my-[5px] py-[3px]" style={{ transform: 'translateX(10%)' }}>산업 맞춤형 혁신 바우처 공급기업 선정</li>
                        <li className=" text-base my-[5px] py-[3px]" style={{ transform: 'translateX(10%)' }}>중소기업 스마트 서비스 공급기업 선정</li>
                        <li className=" text-base my-[5px] py-[3px]" style={{ transform: 'translateX(10%)' }}>성남창업센터(정글ON) 입주기업 선정</li>
                        <li className=" text-base my-[5px] py-[3px]" style={{ transform: 'translateX(10%)' }}>혁신성장유형 벤처기업인증</li>
                        <li className=" text-base my-[5px] py-[3px]" style={{ transform: 'translateX(10%)' }}>창업성장기술개발사업 디딤돌 R&D 선정  </li>
                        <li className=" text-base my-[5px] py-[3px]" style={{ transform: 'translateX(10%)' }}>K-ICT 클라우드 혁신센터 지원사업 성과 우수기업 선정  </li>
                      </ul>
                    </div>
                  </div>
                  <div className=" flex flex-col items-center">
                    <div id="dot4" className="w-[10px] h-[10px] rounded-full border-[#8264E1] border-[2px] " style={{ transform: 'translateX(-50%) translateY(-40%)' }} />
                    <div className="text-xl font-semibold mt-3 text-center w-10">현재
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
          <div className={`text-primary-purple text-xs md:text-base font-extrabold mb-2 mt-28`}>CEO</div>
          <p className="text-lg-title-mobile md:text-lg-title-desktop text-white font-semibold">이종민</p>
          <div className={`px-1 mt-8 md:flex justify-center`}>
            <CeoMini />
          </div>
        </div>
      </div >
    </>
  )
}