import { addWorks, updateWorks } from "../api/works"
import { useState, useEffect } from "react";
import { getWorks } from "../api/works";
import { IoClose } from "react-icons/io5";
import { deleteWorksTags } from "../api/works";
// 추가, 수정 모달

export default function WorksAddModal({ setIsModalOpen, modalMode, item, setWorks }) {
  const [client, setClient] = useState(modalMode === "add" ? "" : item.client);
  const [tags, setTags] = useState(modalMode === "add" ? [] : item.tags);
  const [content, setContent] = useState(modalMode === "add" ? "" : item.content);
  const [image, setImage] = useState(modalMode === "add" ? null : item.img_url);
  const [tag, setTag] = useState("");

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && tag.trim()) {
      e.preventDefault(); // 기본 Enter 동작 방지 (form 제출 등)
      if (tags.length === 5) {
        alert("태그는 최대 5개까지 입력할 수 있습니다.");
        return;
      }
      if (tags.some(tagObj => tagObj.tag === tag.trim())) {
        alert("이미 존재하는 태그입니다.");
        return;
      }
      const newTag = tag.trim();
      setTags([...tags, {
        id: -1,
        tag: newTag
      }]);
      setTag(""); // 입력 필드 초기화
    }
  };
  useEffect(() => {
    if (item === null) return;
    if (modalMode === "update") {
      setClient(item.client);
      setTags(item.tags);
      setContent(item.content);
      setImage(item.img_url);
    }
  }, [modalMode, item]);


  return (
    <div className={`w-full h-full fixed top-0 left-0 bg-black/50 z-50 text-[#666666]`}>
      <div className={`fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 border bg-white w-[40vw]  h-auto shadow-sm px-8 py-6`}>
        <div className="flex flex-col gap-4 items-center">
          <div className="flex items-center">
            <p className="w-20">고객명</p>
            <input type="text" placeholder="ex) 서울시청" value={client}
              className="w-96 border border-gray-300 rounded-md p-2 placeholder:text-gray-300 focus:outline-primary-purple/85"
              onChange={(e) => setClient(e.target.value)} />
          </div>
          <div className="flex items-center">
            <p className="w-20">태그</p>
            <div className="flex flex-col gap-2">
              <div className="flex gap-2">
                {tags && tags.map((item) => {
                  return (
                    <div className={`flex items-center text-sm font-semibold px-2.5 py-1 w-fit bg-gray-200 bg-opacity-60 rounded-full text-gray-600`}>
                      {item.tag}
                      <IoClose className="ml-1 cursor-pointer" onClick={() => {
                        if (tags.length === 1) {
                          alert("태그는 최소 1개 이상 있어야 합니다.");
                          return;
                        }
                        if (window.confirm("정말 삭제하시겠습니까?")) {
                          if (item.id !== -1) {
                            deleteWorksTags(item.id);
                          }
                          setTags(tags.filter(t => t.tag !== item.tag))
                        }
                      }} />
                    </div>
                  )
                })}
              </div>
              <div>
                <input type="text" placeholder="ex) NLP" value={tag}
                  className="w-96 border border-gray-300 rounded-md p-2 placeholder:text-gray-300 focus:outline-primary-purple/85"
                  onChange={(e) => {
                    setTag(e.target.value)
                  }}
                  onKeyUp={handleKeyDown}
                />
                {tags.length === 0 && <p className={`text-sm text-[#ccc]`}> *태그는 최소 1개 이상 추가해주세요</p>}
              </div>
            </div>
          </div>
          <div className="flex items-center">
            <p className="w-20">한줄 요약</p>
            <textarea type="text" placeholder="" value={content}
              rows={3}
              className="resize-none w-96 border border-gray-300 rounded-md p-2 placeholder:text-gray-300 focus:outline-primary-purple/85"
              onChange={(e) => setContent(e.target.value)} />
          </div>
          <div className="flex items-center">
            <p className="w-20">이미지</p>
            <div
              onDrop={(e) => {
                e.preventDefault();
                const file = e.dataTransfer.files[0];
                if (file && (file.type === 'image/webp' || file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg')) {
                  setImage(file);
                } else {
                  setImage(null);
                }
              }}
              onDragOver={(e) => e.preventDefault()}
              className="text-sm text-[#aaa] flex justify-center items-center w-96 aspect-[3/2] border-gray-300 border-dashed border-2 rounded-md"
            >
              <input
                className="input-file"
                type="file"
                accept=".webp,.png, .jpg, .jpeg"
                onChange={(e) => setImage(e.target.files[0])}
              />
              {
                image ? <img src={item && item.img_url === image ? image : URL.createObjectURL(image)} alt="works" className="w-full h-full object-cover rounded-md" /> :
                  "선택한 파일이 없습니다."
              }
            </div>
          </div>
          <div className="flex w-[464px] justify-end gap-2 my-4">
            <button className="border-gray-400 border text-gray-400 px-4 py-2 rounded-md" onClick={() => setIsModalOpen(false)}>취소</button>
            <button className="border-primary-purple border bg-primary-purple/85 text-white px-4 py-2 rounded-md"
              onClick={() => {
                if (!client || tags.length === 0 || !content || !image) {
                  alert("모든 필드를 입력해주세요.");
                  return;
                }
                if (modalMode === "add") {
                  addWorks({ client: client, tags: tags, content: content }, image, setWorks);
                }
                else {
                  updateWorks({
                    id: item.id,
                    client: client,
                    tags: tags,
                    content: content,
                  }, image, setWorks);
                }
                setIsModalOpen(false);
              }}>{modalMode === "add" ? "등록" : "수정"}</button>
          </div>
        </div>
      </div>
    </div>
  )
}