import processImg from "../assets/01_home/04_process_image.jpg"
import { ReactComponent as Step1 } from "../assets/01_home/04_process_step1.svg"
import { ReactComponent as Step2 } from "../assets/01_home/04_process_step2.svg"
import { ReactComponent as Step3 } from "../assets/01_home/04_process_step3.svg"
import { ReactComponent as Step4 } from "../assets/01_home/04_process_step4.svg"
import { useMediaQuery } from 'react-responsive';

export default function Process() {
  const isTablet = useMediaQuery({ maxWidth: 1349 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <>
      <div className=" pt-28 pb-20">
        <div className={`${isMobile ? "w-4/5" : "w-3/5"} mx-auto text-center`}>
          <div className={`text-primary-purple ${isMobile ? " text-xs" : ""} font-extrabold mb-2`}>Process</div>
          <div className={`${isMobile ? "text-lg-title-mobile" : "text-lg-title-desktop"} font-semibold`}>프로젝트 기획부터 개발까지, <br />  업무 프로세스는 이렇게 진행됩니다</div>
          <div id="boxWrap" className={`mt-16 mb-12 ${isTablet ? "flex-col" : "flex justify-evenly items-center"}`}>
            {/* Process 01 */}
            <div className={`flex ${isTablet ? "" : "flex-col pt-10 mb-2"} justify-center items-center`}>
              <div id="icon-wrap1" className="flex justify-center my-2"> <Step1 size={`${isMobile ? "56px" : "80px"}`} /> </div>
              <div className={`w-40`}>
                <div className={`${isMobile ? "text-lg-title-mobile" : " text-lg-title-desktop"} text-[#AAA]`}>01</div>
                <div className={`${isMobile ? "text-lg-title-mobile" : "text-[24px] h-[84px]"} font-semibold  mb-3 text-center p-2`}>
                  <p>기능 정의 및</p>
                  <p>개발 일정 조율</p>
                </div>
              </div>
            </div>
            {/* 띠 */}
            <div className={`${isTablet ? "mx-auto border-l-8 border-dotted border-l-[#AAA] w-44 h-11" : " border-t-4 border-dotted border-t-[#AAA] w-16 h-28"}`} />
            {/* Process 02 */}
            <div className={`flex ${isTablet ? "" : "flex-col pt-10 mb-2"} justify-center items-center`}>
              <div id="icon-wrap2" className="flex justify-center my-2"> <Step2 size={`${isMobile ? "56px" : "80px"}`} /> </div>
              <div className={`w-40`}>
                <div className={`${isMobile ? "text-lg-title-mobile" : " text-lg-title-desktop"} text-[#AAA]`}>02</div>
                <div className={`${isMobile ? "text-lg-title-mobile" : "text-[24px] h-[84px]"} font-semibold  mb-3 text-center p-2`}>
                  <p>기술 개발</p>
                </div>
              </div>
            </div>
            {/* 띠 */}
            <div className={`${isTablet ? "mx-auto border-l-8 border-dotted border-l-[#AAA] w-44 h-11" : " border-t-4 border-dotted border-t-[#AAA] w-16 h-28"}`} />
            {/* Process 03 */}
            <div className={`flex ${isTablet ? "" : "flex-col pt-10 mb-2"} justify-center items-center`}>
              <div id="icon-wrap3" className="flex justify-center my-2"> <Step3 size={`${isMobile ? "56px" : "80px"}`} /> </div>
              <div className={`w-40`}>
                <div className={`${isMobile ? "text-lg-title-mobile" : " text-lg-title-desktop"} text-[#AAA]`}>03</div>
                <div className={`${isMobile ? "text-lg-title-mobile" : "text-[24px] h-[84px]"} font-semibold  mb-3 text-center p-2`}>
                  <p>솔루션 납품</p>
                </div>
              </div>
            </div>
            {/* 띠 */}
            <div className={`${isTablet ? "mx-auto border-l-8 border-dotted border-l-[#AAA] w-44 h-11" : " border-t-4 border-dotted border-t-[#AAA] w-16 h-28"}`} />
            {/* Process 04 */}
            <div className={`flex ${isTablet ? "" : "flex-col"} justify-center items-center pt-10 mb-2`}>
              <div id="icon-wrap4" className="flex justify-center my-2"> <Step4 size={`${isMobile ? "56px" : "80px"}`} /> </div>
              <div className={`w-40`}>
                <div className={`${isMobile ? "text-lg-title-mobile" : " text-lg-title-desktop"} text-[#AAA]`}>04</div>
                <div className={`${isMobile ? "text-lg-title-mobile" : "text-[24px] h-[84px]"} font-semibold  mb-3 text-center p-2`}>
                  <p>유지 보수</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}