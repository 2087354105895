import { theme } from "../shared/theme";
import { useMediaQuery } from 'react-responsive';

export default function WorksBlock({ item }) {
  const isMobile = useMediaQuery({ maxWidth: theme.size.mobile });

  return (

    <div className={`${isMobile ? "py-4" : "px-12 py-4"} flex flex-col gap-2`}
    // onClick={() => { if (isModalOpen) { return }; setIsModalOpen(true); setCurrentItem(item) }}
    >
      <img src={item.img_url} alt="works" className={`${isMobile ? "aspect-[3/2]" : " aspect-[4/3]"} rounded-lg object-cover`}
      />
      <div className="flex gap-2 mt-2">
        {item.tags.map((item) => (
          <p className={`text-sm font-semibold px-2.5 py-1 w-fit block bg-gray-200 bg-opacity-60 rounded-full text-gray-600`}>
            {item.tag}
          </p>
        ))}
      </div>
      <p className={`${isMobile ? "text-lg-title-mobile" : "text-lg-title-desktop"} font-semibold`}
      >{item.client}</p>
      <p className={`${isMobile ? "text-desc-tablet" : "text-desc-desktop"} text-[#666666] whitespace-nowrap overflow-hidden text-ellipsis`}>{item.content}</p>
    </div>
  )
}

