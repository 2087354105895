export default function CeoMini() {

  return (
    <div className={`mt-10 md:mt-0 md:mr-[30px] text-white`}>
      <div className={`grid text-desc-tablet md:text-base py-[10px]`} style={{ gridTemplateColumns: "1fr 5fr" }}>
        <div className={`font-semibold mb-[10px]`}>대표</div>
        <div className={`font-normal mb-[10px]`}> 뉴로플로우 주식회사</div>
      </div>
      <div style={{ height: "2px", backgroundColor: "rgb(255,255,255,0.2)" }} />
      <div className={`grid text-desc-tablet md:text-base py-[10px]`} style={{ gridTemplateColumns: "1fr 5fr" }}>
        <div className={`font-semibold mb-[10px]`}>학력</div>
        <div className={`flex flex-col gap-2 font-normal mb-[10px]`}>
          <p>성균관대학교 전자전기공학부 학사</p>
          <p>성균관대학교 인공지능학과 석사</p>
        </div>
      </div>
      <div style={{ height: "2px", backgroundColor: "rgb(255,255,255,0.2)" }} />
      <div className={`grid text-desc-tablet md:text-base py-[10px]`} style={{ gridTemplateColumns: "1fr 5fr" }}>
        <div className={`font-semibold mb-[10px]`}>강의</div>
        <div className={`flex flex-col gap-2 font-normal mb-[10px]`}>
          <p>대학 및 기업 다수 출강</p>
          <p>삼성전자, 라인플러스, 한국디지털융합진흥원, 성균관대학교 등</p>
        </div>
      </div>
      <div style={{ height: "2px", backgroundColor: "rgb(255,255,255,0.2)" }} />
      <div className={`text-desc-tablet md:text-base font-semibold my-[10px]`}>연구 및 개발</div>
      <div className={`grid text-desc-tablet md:text-base py-[10px]`} style={{ gridTemplateColumns: "1fr 5fr" }}>
        <div className={`font-semibold mb-[10px]`}>R&D</div>
        <div className={`flex flex-col gap-2 font-normal mb-[10px]`}>
          <p className="break-keep">의료 데이터 프라이버시 보존을 위한 분산 환경에서의 연합 AI 컴퓨팅 모델 개발</p>
          <p className="break-keep">AI 기반 다중센싱을 이용한 Brain-Body 인터페이스 기술 개발</p>
          <p>이외 다수의 기관 및 기업 R&D 과제 참여</p>
        </div>
      </div>
      <div className={`grid text-desc-tablet md:text-base py-[10px]`} style={{ gridTemplateColumns: "1fr 5fr" }}>
        <div className={`font-semibold mb-[10px]`}>특허</div>
        <div className={`flex flex-col gap-2 font-normal mb-[10px]`}>
          <p className="break-keep">우울증 진단을 위한 인공지능 연합학습 방법 및 시스템</p>
          <p className="break-keep">우울증 치료를 위한 인공지능 기반의 미러 카운슬링 방법</p>
          <p className="break-keep"> 후각센서 기반 인공지능을 이용한 마약 검출방법 </p>
        </div>
      </div>
      <div className={`grid text-desc-tablet md:text-base py-[10px]`} style={{ gridTemplateColumns: "1fr 5fr" }}>
        <div className={`font-semibold mb-[10px]`}>SW</div>
        <div className={`flex flex-col gap-2 font-normal mb-[10px]`}>
          <p className="break-keep">SNS 상의 가짜 뉴스 판별 인공지능</p>
          <p className="break-keep"> 범죄 상황을 포착하기 위한 딥러닝 기반 영상 분류 시스템</p>
        </div>
      </div>
    </div>
  )
}


