
export const getWorks = async (setWorks) => {
  const worksUrl = "/api/works";
  try {
    // apache2 설정파일 ssl 문제로 db 데이터 긁어다 적용(배포 후 풀어야)
    const response = await fetch(worksUrl);
    const data = await response.json();
    // const data = { "status": 1, "works": [{ "id": 5, "img_url": "http://localhost:1998/uploads/1736500257820-347304276.png", "client": "인천대학교", "tag": "Computer Vision", "content": "영상 속 작업 종류 분류", "published_at": "2025-01-10T09:10:57.000Z" }, { "id": 6, "img_url": "http://localhost:1998/uploads/1736501798091-662722589.png", "client": "한국철도기술연구원", "tag": "NLP", "content": "사고사례 문서 내 사고 원인 및 결과 키워드 추출", "published_at": "2025-01-10T09:20:23.000Z" }, { "id": 7, "img_url": "http://localhost:1998/uploads/1736501809902-286004655.png", "client": "한국철도기술연구원", "tag": "Computer Vision", "content": "영상 내 잠재적 사고 위험 대상 인식", "published_at": "2025-01-10T09:20:23.000Z" }, { "id": 8, "img_url": "http://localhost:1998/uploads/1736501826475-939571746.png", "client": "서울시청", "tag": "NLP", "content": "문서 내 개인정보 식별 및 마스킹", "published_at": "2025-01-10T09:20:23.000Z" }, { "id": 9, "img_url": "http://localhost:1998/uploads/1736501841356-301754112.png", "client": "한국소비자원", "tag": "NLP", "content": "소비자 불만 유형 분류", "published_at": "2025-01-10T09:20:23.000Z" }, { "id": 10, "img_url": "http://localhost:1998/uploads/1736501848744-261516452.png", "client": "고객사", "tag": "Computer Vision", "content": "이미지 및 음성 생성을 통한 영상 제작", "published_at": "2025-01-10T09:20:23.000Z" }, { "id": 11, "img_url": "http://localhost:1998/uploads/1736501857439-43509605.png", "client": "고객사", "tag": "Computer Vision", "content": "하수도 내 이물질 탐지", "published_at": "2025-01-10T09:20:23.000Z" }, { "id": 12, "img_url": "http://localhost:1998/uploads/1736501865737-754401152.png", "client": "고객사", "tag": "NLP", "content": "문서 분석을 통한 여론 동향 파악", "published_at": "2025-01-10T09:20:23.000Z" }] }
    setWorks(data.works);
    console.log("data:", data.works);
  } catch (error) {
    console.error("Error fetching works:", error);
    throw error;
  }
};

export const addWorks = async (works, image, setWorks) => {
  const worksUrl = "/api/works";
  try {
    const formData = new FormData();
    formData.append('image', image);
    formData.append('client', works.client);
    formData.append('tags', JSON.stringify(works.tags));
    formData.append('content', works.content);

    const response = await fetch(worksUrl, { method: "POST", body: formData });
    const data = await response.json();
    getWorks(setWorks);
    return data;
  } catch (error) {
    console.error("Error adding works:", error);
    throw error;
  }
};

export const updateWorks = async (works, image, setWorks) => {
  const worksUrl = `/api/works/${works.id}`;
  try {
    const formData = new FormData();
    if (image) formData.append('image', image);
    if (works.client) formData.append('client', works.client);
    if (works.tags) formData.append('tags', JSON.stringify(works.tags));
    if (works.content) formData.append('content', works.content);

    const response = await fetch(worksUrl, { method: "PUT", body: formData });
    const data = await response.json();
    getWorks(setWorks);
    return data;
  } catch (error) {
    console.error("Error updating works:", error);
    throw error;
  }
};

export const deleteWorks = async (worksId, setWorks) => {
  const worksUrl = `/api/works/${worksId}`;
  try {
    const response = await fetch(worksUrl, { method: "DELETE" });
    const data = await response.json();
    getWorks(setWorks);
  } catch (error) {
    console.error("Error deleting works:", error);
    throw error;
  }
};

export const deleteWorksTags = async (id) => {
  const worksUrl = `/api/works/tags/${id}`;
  try {
    const response = await fetch(worksUrl, { method: "DELETE" });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error deleting works tags:", error);
    throw error;
  }
};