
export default function NewsBlock({ item, section }) {

  return (
    <div className={`${section === 0 ? "flex" : ""} cursor-pointer `}
      onClick={() => {
        window.open(item.src_url, '_blank');
      }}>
      <img src={item.img_url} alt="news" className={`aspect-[5/3] rounded-lg object-cover `}
      />
      <div className={`${section === 0 ? "px-6" : "px-2 "} flex-col flex justify-evenly py-4`}>
        <p className={`${section === 0 ? "text-[28px]" : section === 1 ? "text-[24px]" : "text-[20px]"} font-semibold line-clamp-3 break-keep overflow-hidden text-ellipsis`}
        >{item.title}</p>
        {
          section === 0 &&
          <p className={`text-base md:text-lg text-[#666666] line-clamp-4 break-keep overflow-hidden text-ellipsis`} >
            {
              item.first_paragraph 
            }</p>
        }
        <div className="flex justify-between text-[#818181] text-xs md:text-sm mt-4">
          <p>{item.published_at.split('T')[0]}</p>
        </div>
      </div>
    </div>
  )
}

