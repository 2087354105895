import { ReactComponent as Aipic } from "../assets/01_home/02_home_about_ai.svg"
import { ReactComponent as Edupic } from "../assets/01_home/02_home_about_edu.svg"
import { ReactComponent as Techpic } from "../assets/01_home/02_home_about_tech.svg"
import { useMediaQuery } from 'react-responsive';
import { theme } from "../shared/theme";
export default function AboutPart() {
  const isTablet = useMediaQuery({ maxWidth: theme.size.tablet });
  const isMobile = useMediaQuery({ maxWidth: theme.size.mobile });

  return (
    <>
      <div className={`${isMobile ? "w-4/5 py-10" : "w-3/5 py-20"} gap-2 mx-auto  mt-10`}>
        <div className={`text-primary-purple ${isMobile ? " text-xs" : ""} font-extrabold mb-2 text-center`}>About</div>
        <div className={`${isMobile ? "text-lg-title-mobile" : "text-lg-title-desktop"} font-semibold text-center`}>
          <p >사회 친화적 딥테크 기업,</p>
          <p>뉴로플로우의 사업을 소개합니다</p>
        </div>
        <div id="boxWrap" className={`flex justify-evenly my-16  gap-8 ${isTablet ? " flex-col" : ""}`}>
          <div className={`${isTablet ? "" : "w-1/3"} py-[30px]  px-8 flex flex-col justify-center items-center`}>
            <Aipic size={`64px`} />
            <div className="mt-[30px]  mb-3 text-center">
              <div className={`${isMobile ? "text-lg-title-mobile" : "text-lg-title-desktop"} font-semibold  mb-3`}>AI solution</div>
              <div className={`${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>
                <p>사회 발전과 편의를 위한 AI 솔루션을 개발합니다</p>
              </div>
            </div>
          </div>
          <div className={`${isTablet ? "" : "w-1/3"} py-[30px]  px-8 flex flex-col justify-center items-center`}>
            <Techpic size={`64px`} />
            <div className="mt-[30px]  mb-3 text-center">
              <div className={`${isMobile ? "text-lg-title-mobile" : "text-lg-title-desktop"} font-semibold  mb-3`}>기술 자문</div>
              <div className={`${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>
                <p> AI 도입 가능성, 필요 기술 및 TTA 기술 검증 등을 위한 자문을 제공합니다</p>
              </div>
            </div>
          </div>
          <div className={`${isTablet ? "" : "w-1/3"} py-[30px]  px-8 flex flex-col justify-center items-center `}>
            <Edupic size={`64px`} />
            <div className="mt-[30px]  mb-3 text-center">
              <div className={`${isMobile ? "text-lg-title-mobile" : "text-lg-title-desktop"} font-semibold  mb-3`}>교육</div>
              <div className={`${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>
                <p >기업(직무교육), 교육 기관 등에 AI 및 프로그래밍 교육 서비스를 제공합니다</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}