import { Link } from 'react-router-dom';
import { ReactComponent as SvgHome } from "../assets/00_logo/neuroflow_logo_W.svg"
import { ReactComponent as Svg } from "../assets/00_logo/neuroflow_logo.svg"
import { useLocation } from "react-router-dom";
import { useState } from 'react';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react'

export default function Headerbar({ visible, curHeader }) {
  const location = useLocation();
  const { pathname } = location;

  return (
    <div className={`absolute ${visible ? 'top-0 transition-transform duration-500' : '-translate-y-[85px] transition-transform duration-500'} flex w-full justify-center z-50 `}>
      <div className={`flex w-screen h-[80px] fixed ${pathname === "/" && curHeader === "None" ? "bg-transparent shadow-neutral-600" : "bg-white "} shadow-sm`}>
        <div className={`w-full max-w-6xl mx-auto px-6 pt-[10px] flex justify-between items-center`}>
          {/* // 데스크탑용 상단바 */}
          <div id="logo" className={`flex`}>
            <Link to="/" style={{ textDecoration: "none", color: "black" }}>
              {pathname === "/" && curHeader === "None" ? <SvgHome height="24px" /> : <Svg height="24px" />}
            </Link>
          </div>
          <div className="inline-flex mr-20 gap-20">
            <Link to="/about" style={{ textDecoration: "none", color: "black" }}>
              <div className={`relative flex items-end h-[30px] font-semibold ${pathname === "/" && curHeader === "None" ? "text-white" : pathname === "/about" ? "text-primary-purple" : "text-primary-gray"}`}>ABOUT
                <div className={`absolute z-90 -bottom-[14px] left-1/2 -translate-x-1/2 rounded-full w-1.5 h-1.5 ${pathname === "/about" ? "bg-primary-purple" : ""}`} />
              </div>
            </Link>
            {/* <Link to="/service" style={{ textDecoration: "none", color: "black" }}>
              <div className={`relative group flex items-end h-[30px] font-semibold ${pathname === "/" && curHeader === "None" ? "text-white" : pathname === "/service" ? "text-primary-purple" : "text-primary-gray"}`}>SERVICE
                <div className={`absolute z-90 -bottom-[14px] left-1/2 -translate-x-1/2 rounded-full w-1.5 h-1.5 ${pathname === "/solution" ? "bg-primary-purple" : ""}`} />
                <div className={`pointer-events-none absolute top-full left-1/2 transform -translate-x-1/2 mb-2  px-2 py-3 bg-white text-[#AAA] rounded opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex flex-col w-40`} >
                  <Link to="/service/rnd" style={{ textDecoration: "none", color: "black" }}>
                    <div className="text-[#AAA] border-b-2 border-b-[#ddd] text-center pb-1 mb-1">R&D 사업 Consulting</div>
                  </Link>
                  <Link to="/service/consulting" style={{ textDecoration: "none", color: "black" }}>
                    <div className="text-[#AAA] text-center">AI 기술 개발 Consulting</div>
                  </Link>
                </div>
              </div>
            </Link> */}
            <Popover className="relative">
              <PopoverButton className={`focus:outline-none flex items-end h-[30px] font-semibold ${pathname === "/" && curHeader === "None" ? "text-white" : pathname.includes("/service") ? "text-primary-purple" : "text-primary-gray"}`}>SERVICE</PopoverButton>
              <div className={`absolute z-90 -bottom-[14px] left-1/2 -translate-x-1/2 rounded-full w-1.5 h-1.5 ${pathname.includes("/service") ? "bg-primary-purple" : ""}`} />
              <PopoverPanel anchor="bottom" className="border z-50 mt-3 flex flex-col bg-white text-[#6F6E6E] rounded-lg">
                <a href="/service/solution" className="px-4 py-2 text-center border-b border-[#ddd] cursor-pointer">Solution</a>
                <a href="/service/consulting" className="px-4 py-2 text-center cursor-pointer">Consulting</a>
              </PopoverPanel>
            </Popover>
            <Link to="/works" style={{ textDecoration: "none", color: "black" }}>
              <div className={`relative flex items-end h-[30px] font-semibold ${pathname === "/" && curHeader === "None" ? "text-white" : pathname === "/works" ? "text-primary-purple" : "text-primary-gray"}`}>WORKS
                <div className={`absolute z-90 -bottom-[14px] left-1/2 -translate-x-1/2 rounded-full w-1.5 h-1.5 ${pathname === "/works" ? "bg-primary-purple" : ""}`} />
              </div>
            </Link>
            <Link to="/news" style={{ textDecoration: "none", color: "black" }}>
              <div className={`relative flex items-end h-[30px] font-semibold ${pathname === "/" && curHeader === "None" ? "text-white" : pathname === "/news" ? "text-primary-purple" : "text-primary-gray"}`}>NEWS
                <div className={`absolute z-90 -bottom-[14px] left-1/2 -translate-x-1/2 rounded-full w-1.5 h-1.5 ${pathname === "/news" ? "bg-primary-purple" : ""}`} />
              </div>
            </Link>
            <Link to="/contact" style={{ textDecoration: "none", color: "black" }}>
              <div className={`relative flex items-end h-[30px] font-semibold ${pathname === "/" && curHeader === "None" ? "text-white" : pathname === "/contact" ? "text-primary-purple" : "text-primary-gray"}`}>CONTACT
                <div className={`absolute z-90 -bottom-[14px] left-1/2 -translate-x-1/2 rounded-full w-1.5 h-1.5 ${pathname === "/contact" ? "bg-primary-purple" : ""}`} />
              </div>
            </Link>
          </div>
        </div>

      </div>
    </div>
  )
}