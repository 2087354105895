import { useEffect, useState } from "react";
import RndConsulting from "../consulting/RndConsulting";
import AiConsulting from "../consulting/AiConsulting";
import Footer from "../components/Footer";
import { useMediaQuery } from 'react-responsive';
import { useNavigate, useLocation } from "react-router-dom";
import { theme } from "../shared/theme";

export default function Consulting() {
  const isTablet = useMediaQuery({ maxWidth: theme.size.tablet });
  const isMobile = useMediaQuery({ maxWidth: theme.size.mobile });
  const navigate = useNavigate();
  const { pathname } = useLocation(); // 현재 경로 가져오기
  const [curTab, setCurTab] = useState(pathname.split('/').pop());
  useEffect(() => {
    setCurTab(pathname.split('/').pop())
  }, [pathname])

  return (
    <>
      <div className="w-screen">
        <div className={`w-full max-w-6xl mt-[100px]  px-6 mx-auto pt-20 pb-5`}>
          <div className={`mb-4 font-extrabold ${isMobile ? "text-main-banner-mobile" : "text-main-banner-desktop"}`}>Consulting</div>
          <div className={`${isMobile ? "text-lg-title-mobile" : "text-lg-title-desktop"} font-semibold`}>
            <p>더 확실한 성공을 위한 컨설팅,</p>
            <p>뉴로플로우를 통해 만나보세요</p>
          </div>
          <div className={`flex gap-10 mt-12`}>
            <div id="tab_rnd" className={`flex relative ${isTablet ? "text-desc-tablet" : "text-desc-desktop"} py-[10px] cursor-pointer w-28 font-semibold justify-center ${curTab === "rnd" ? "text-primary-purple" : ""}`} onClick={() => navigate("/service/consulting/rnd")}>R&D 사업
              <div id="dot_rnd" className={`absolute -bottom-[3px] left-[50%] w-[6px] h-[6px] rounded-full ${curTab === "rnd" ? "bg-primary-purple" : "bg-transparent"}`} style={{ transform: "translateX(-50%)" }} />
            </div>
            <div id="tab_ai" className={`flex relative ${isTablet ? "text-desc-tablet" : "text-desc-desktop"} py-[10px] cursor-pointer w-28 font-semibold justify-center ${curTab === "ai" ? "text-primary-purple" : ""}`} onClick={() => navigate("/service/consulting/ai")}>AI 기술 개발
              <div id="dot_ai" className={`absolute -bottom-[3px] left-[50%] w-[6px] h-[6px] rounded-full ${curTab === "ai" ? "bg-primary-purple" : "bg-transparent"}`} style={{ transform: "translateX(-50%)" }} />
            </div>
          </div>
        </div>
        {curTab === "rnd" ? <RndConsulting /> : <AiConsulting />}
      </div>
      <Footer />
    </>
  )
}