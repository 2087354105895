import React from "react";
import { ReactComponent as Pic11 } from "../assets/03_service/03_ai_1-1.svg"
import { ReactComponent as Pic12 } from "../assets/03_service/03_ai_1-2.svg"
import { ReactComponent as Pic13 } from "../assets/03_service/03_ai_1-3.svg"
import { ReactComponent as Pic21 } from "../assets/03_service/03_ai_2-1.svg"
import { ReactComponent as Pic22 } from "../assets/03_service/03_ai_2-2.svg"
import { ReactComponent as Pic23 } from "../assets/03_service/03_ai_2-3.svg"

import aiImg from "../assets/03_service/service_bg2.webp"
import { useMediaQuery } from 'react-responsive';
import { theme } from "../shared/theme";

export default function AiConsulting() {
  const isTablet = useMediaQuery({ maxWidth: theme.size.tablet });
  const isMobile = useMediaQuery({ maxWidth: theme.size.mobile });

  return (
    <>
      <div className={`relative w-screen ${isMobile ? "h-[320px]" : " h-[400px]"} mx-auto bg-no-repeat bg-cover bg-center py-14`} style={{ backgroundImage: `url(${aiImg})` }}>
        {/* 첫 section */}
        <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-80  py-14">
          <div className={`w-full max-w-6xl  px-6 mx-auto`}>
            <div className={`${isMobile ? " text-xs" : ""} font-extrabold text-[#6F6F6F]  mb-2 mt-5`}>AI Technology Consulting</div>
            <div className={`${isMobile ? "text-lg-title-mobile" : "text-lg-title-desktop"} font-semibold text-[#D1C5F4]`}>AI 기술 개발 컨설팅 서비스</div>
            <div className={`text-white ${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>
              <p>뉴로플로우는 고객의 비즈니스 문제를 이해하고,</p>
              <p> 맞춤형 AI 전략과 솔루션을 제공하여 AI 기반 기술을 </p>
              <p> 성공적으로 구현하고 최적의 결과를 제공합니다.</p>
            </div>
          </div >
        </div>
      </div >
      {/* 두번째 section */}
      <div className="w-full max-w-6xl px-6 mx-auto mt-10 py-20">
        <p className={`${isMobile ? "text-lg-title-mobile" : "text-lg-title-desktop"}  font-semibold`}>AI 기술 컨설팅을 받으면 <span className="text-primary-purple">어떤 이점</span>이 있을까요?</p>
        <div id="boxWrap" className={`flex justify-evenly my-16  gap-8 ${isTablet ? " flex-col" : ""}`}>
          <div className={`${isTablet ? "" : "w-1/3"} border border-primary-purple rounded-xl bg-[#FCFBFF]  py-[30px]  px-8 flex flex-col justify-center`}>
            <Pic11 size={`64px`} />
            <div className="mt-[30px]  mb-3">
              <div className={`${isMobile ? "text-lg-title-mobile" : "text-lg-title-desktop"} font-semibold  mb-3`}>최신 AI 기술 도입 및 활용 극대화</div>
              <div className={`${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>
                <p>AI 기술은 빠르게 발전하고 있습니다. 뉴로플로우는 최신 기술과 트렌드를 파악해 기업에 적합한 솔루션을 제안합니다.</p>
              </div>
            </div>
          </div>
          <div className={`${isTablet ? "" : "w-1/3"} border border-primary-purple rounded-xl bg-[#FCFBFF]  py-[30px]  px-8 flex flex-col justify-center`}>
            <Pic12 size={`64px`} />
            <div className="mt-[30px]  mb-3">
              <div className={`${isMobile ? "text-lg-title-mobile" : "text-lg-title-desktop"} font-semibold  mb-3`}>맞춤형 전략 제공</div>
              <div className={`${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>
                <p>AI 기술이 고객의 요구나 시장 상황에 적합하도록 실행 전략을 설계하고, 기획 단계부터 개발, 유지 보수까지의 전 과정을
                  체계적으로 지원합니다.</p>
              </div>
            </div>
          </div>
          <div className={`${isTablet ? "" : "w-1/3"} border border-primary-purple rounded-xl bg-[#FCFBFF]  py-[30px]  px-8 flex flex-col justify-center`}>
            <Pic13 size={`64px`} />
            <div className="mt-[30px]  mb-3">
              <div className={`${isMobile ? "text-lg-title-mobile" : "text-lg-title-desktop"} font-semibold  mb-3`}>리스크 최소화 및 성공 가능성 극대화</div>
              <div className={`${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>
                <p>
                  기술적, 재정적, 운영적 리스크를 사전에 파악하고 관리 방안을 제공합니다. AI 프로젝트의 복잡성을 효과적으로 관리해 실패 가능성을 줄이고 성공 확률을 높입니다.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div >
      <div data-id="divider" className={`bg-[#EEEEEE] w-full h-8`} />
      {/* 세번째 section */}
      <div className={`bg-gradient-to-b from-transparent to-[#FAF8FF]`}>
        <div className="w-full max-w-6xl px-6 mx-auto mt-10 py-20">
          <p className={`text-center font-semibold text-primary-purple ${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}> AI 기술 개발 컨설팅</p>
          <p className={`text-center ${isMobile ? "text-lg-title-mobile" : "text-lg-title-desktop"}  font-semibold`}>
            최적화된 AI 기술을 제안하고
            전문 컨설팅을 제공하여 성공 가능성을 높입니다</p>
          <div id="boxWrap" className={`flex justify-evenly my-16  gap-8 ${isTablet ? " flex-col" : ""}`}>
            <div className={`${isTablet ? "" : "w-1/3"} py-[30px]  px-8 flex flex-col justify-center items-center`}>
              <Pic21 size={`64px`} />
              <div className="mt-[30px]  mb-3 text-center">
                <div className={`${isMobile ? "text-lg-title-mobile" : "text-lg-title-desktop"} font-semibold  mb-3`}>AI 기반 기술 컨설팅</div>
                <div className={`${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>
                  <p>특정 AI 기반 기술을 개발 및 구현에 필요한 기술을 제안합니다 </p>
                </div>
              </div>
            </div>
            <div className={`${isTablet ? "" : "w-1/3"}  py-[30px]  px-8 flex flex-col justify-center items-center`}>
              <Pic22 size={`64px`} />
              <div className="mt-[30px]  mb-3 text-center">
                <div className={`${isMobile ? "text-lg-title-mobile" : "text-lg-title-desktop"} font-semibold  mb-3`}>AI 모델 제안</div>
                <div className={`${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>
                  <p>개발하고자 하는 기능과 유사한 사례(논문) 서칭을 통해 필요한 모델을 제안합니다</p>
                </div>
              </div>
            </div>
            <div className={`${isTablet ? "" : "w-1/3"} py-[30px]  px-8 flex flex-col justify-center items-center`}>
              <Pic23 size={`64px`} />
              <div className="mt-[30px]  mb-3 text-center">
                <div className={`${isMobile ? "text-lg-title-mobile" : "text-lg-title-desktop"} font-semibold  mb-3`}>실무 가이드 라인 제안</div>
                <div className={`${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>
                  <p>프로젝트 규모와 요구 사항에 맞는 자원을 제안하고, 단계별 목표와 일정 관리를 통해 효율성을 극대화합니다</p>
                </div>
              </div>
            </div>
          </div>
        </div >
      </div>
      {/* 네번째 section */}
      {/* <div className={`${isMobile ? "w-4/5 py-10" : "w-3/5 py-20"} gap-2 mx-auto  mt-10`}>
        <p className={`${isMobile ? " text-xs" : "text-sm"} font-semibold px-4 py-2 w-fit block bg-black rounded-full text-white`}>
          AI 기술
        </p>
        <p className={`${isMobile ? "text-lg-title-mobile" : "text-lg-title-desktop"}  font-semibold mt-2 mb-8`}>
          AI 기술 개발 컨설팅 프로세스</p>
        <div className={`border h-56 bg-[#D1D1D1]`} />
      </div > */}
    </>
  );
};
