import React, { useEffect, useState } from 'react';
import QuillViewer from "../components/QuillViewer";
import { useLocation } from 'react-router-dom';
import { GoArrowLeft, GoArrowRight } from "react-icons/go";

export default function NewsDetail() {

  const location = useLocation(); // navigate로 전달된 state 가져오기

  const { item } = location.state || {}; // state에서 content 추출
  // 이전글 또는 다음글의 경우 현재 id 기준으로 해서 db에서 가져오기... 

  return (
    <div className="w-screen">
      <div className="w-full max-w-6xl mt-[100px] px-6 mx-auto border">
        <div className="flex justify-between mt-4 mb-2 px-5">
          <p className="text-base text-[#757575]">{item.from}</p>
          <p className="text-base text-[#757575]">{item.published_at}</p>
        </div>
        <p className="px-5 text-[#aaa] text-sm cursor-pointer" onClick={() => window.open(item.link, '_blank')} >원본 기사 보기</p>
        <QuillViewer content={item.content} />
      </div>
      <div className="w-full max-w-6xl px-6 mx-auto flex justify-between my-8">
        <div className="flex items-center gap-2 cursor-pointer text-[#757575]">
          <GoArrowLeft size={28} />
          <p className="text-base ]" >이전글</p>
        </div>
        <div className="flex items-center">
          <p className="text-base text-primary-purple cursor-pointer">목록으로</p>
        </div>
        <div className="flex items-center gap-2 cursor-pointer text-[#757575]">
          <p className="text-base">다음글</p>
          <GoArrowRight size={28} />
        </div>
      </div>
    </div>
  )
}