import React from "react";
import rndImg from "../assets/03_service/service_bg.webp"
import { useMediaQuery } from 'react-responsive';
import { theme } from "../shared/theme";
import { ReactComponent as Pic11 } from "../assets/03_service/03_rnd_1-1.svg"
import { ReactComponent as Pic12 } from "../assets/03_service/03_rnd_1-2.svg"
import { ReactComponent as Pic13 } from "../assets/03_service/03_rnd_1-3.svg"
import { ReactComponent as Pic21 } from "../assets/03_service/03_rnd_2-1.svg"
import { ReactComponent as Pic22 } from "../assets/03_service/03_rnd_2-2.svg"
import { ReactComponent as Pic23 } from "../assets/03_service/03_rnd_2-3.svg"
import { ReactComponent as Pic31 } from "../assets/03_service/03_rnd_3-1.svg"
import { ReactComponent as Pic32 } from "../assets/03_service/03_rnd_3-2.svg"

export default function RndConsulting() {
  const isTablet = useMediaQuery({ maxWidth: theme.size.tablet });
  const isMobile = useMediaQuery({ maxWidth: theme.size.mobile });

  return (
    <>
      <div className={`relative w-screen ${isMobile ? "h-[320px]" : " h-[400px]"} mx-auto bg-no-repeat bg-cover bg-center py-14`} style={{ backgroundImage: `url(${rndImg})` }}>
        {/* 첫 section */}
        <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-80  py-14">
          <div className={`w-full max-w-6xl  px-6 mx-auto`}>
            <div className={`${isMobile ? " text-xs" : ""} font-extrabold text-[#6F6F6F]  mb-2 mt-5`}>R&D Consulting</div>
            <div className={`${isMobile ? "text-lg-title-mobile" : "text-lg-title-desktop"} font-semibold text-[#D1C5F4]`}>R&D 사업 컨설팅 서비스</div>
            <div className={`text-white ${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>
              <p>R&D 사업의 복잡한 인증 절차를 전문적으로 해결하고,</p>
              <p>R&D 과정 전반에 걸쳐 체계적으로 컨설팅하여 </p>
              <p>성공적으로 완료할 수 있도록 지원해 드립니다</p>
            </div>
          </div >
        </div>
      </div >
      {/* 두번째 section */}
      <div className="w-full max-w-6xl px-6 mx-auto mt-10 py-20">
        <p className={`${isMobile ? "text-lg-title-mobile" : "text-lg-title-desktop"}  font-semibold`}>R&D 사업 컨설팅을 받으면 <span className="text-primary-purple">어떤 이점</span>이 있을까요?</p>
        <div id="boxWrap" className={`flex justify-evenly my-16  gap-8 ${isTablet ? " flex-col" : ""}`}>
          <div className={`${isTablet ? "" : "w-1/3"} border border-primary-purple rounded-xl bg-[#FCFBFF]  py-[30px]  px-8 flex flex-col justify-center`}>
            <Pic11 size={`64px`} />
            <div className="mt-[30px]  mb-3">
              <div className={`${isMobile ? "text-lg-title-mobile" : "text-lg-title-desktop"} font-semibold  mb-3`}>시간 절약</div>
              <div className={`${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>
                <p>정부 지원 사업이나 인증 절차는 매우 복잡하고 세부적인
                  요구사항을 충족해야 하므로 전문성을 바탕으로 필요한
                  서류와 절차를 체계적으로 준비해서 시간을 단축시켜줍니다.</p>
              </div>
            </div>
          </div>
          <div className={`${isTablet ? "" : "w-1/3"} border border-primary-purple rounded-xl bg-[#FCFBFF]  py-[30px]  px-8 flex flex-col justify-center`}>
            <Pic12 size={`64px`} />
            <div className="mt-[30px]  mb-3">
              <div className={`${isMobile ? "text-lg-title-mobile" : "text-lg-title-desktop"} font-semibold  mb-3`}>성공 가능성 증가</div>
              <div className={`${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>
                <p>인증 및 지원 사업의 심사 기준과 통과 요건을 정확히 이해하고, 심사위원들이 중요하게 보는 요소를 강조하는 맞춤형 전략을 제공하여 리스크를 최소화합니다.</p>
              </div>
            </div>
          </div>
          <div className={`${isTablet ? "" : "w-1/3"} border border-primary-purple rounded-xl bg-[#FCFBFF]  py-[30px]  px-8 flex flex-col justify-center`}>
            <Pic13 size={`64px`} />
            <div className="mt-[30px]  mb-3">
              <div className={`${isMobile ? "text-lg-title-mobile" : "text-lg-title-desktop"} font-semibold  mb-3`}>최적의 사업 기획 및 보고서 작성</div>
              <div className={`${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>
                <p>정부 기관이 요구하는 서식, 문구, 인증 요건에 철저히 맞춘 서류를 준비하여 사업 가능성을 높이는 데 기여합니다.</p>
              </div>
            </div>
          </div>
        </div>
      </div >
      <div data-id="divider" className={`bg-[#EEEEEE] w-full h-8`} />
      {/* 세번째 section */}
      <div className={`bg-gradient-to-b from-transparent to-[#FAF8FF]`}>
        <div className="w-full max-w-6xl px-6 mx-auto mt-10 py-20">
          <p className={`text-center text-primary-purple ${isMobile ? "text-desc-tablet" : "text-desc-desktop"}  font-semibold`}>기술 성능 인증 컨설팅</p>
          <p className={`text-center ${isMobile ? "text-lg-title-mobile" : "text-lg-title-desktop"}  font-semibold`}>
            R&D 사업의 복합한 인증 절차를 전문 컨설팅을 통해
            인증 통과할 수 있도록 전략을 제시합니다</p>
          <div id="boxWrap" className={`flex justify-evenly my-16  gap-8 ${isTablet ? " flex-col" : ""}`}>
            <div className={`${isTablet ? "" : "w-1/3"} py-[30px]  px-8 flex flex-col justify-center items-center`}>
              <Pic21 size={`64px`} />
              <div className="mt-[30px]  mb-3 text-center">
                <div className={`${isMobile ? "text-lg-title-mobile" : "text-lg-title-desktop"} font-semibold  mb-3`}>인증기관과 소통</div>
                <div className={`${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>
                  <p>인증기관과의 원활한 커뮤니케이션을 통해 인증 절차 소요시간을 단축시킵니다 </p>
                </div>
              </div>
            </div>
            <div className={`${isTablet ? "" : "w-1/3"}  py-[30px]  px-8 flex flex-col justify-center items-center`}>
              <Pic22 size={`64px`} />
              <div className="mt-[30px]  mb-3 text-center">
                <div className={`${isMobile ? "text-lg-title-mobile" : "text-lg-title-desktop"} font-semibold  mb-3`}>행정 처리</div>
                <div className={`${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>
                  <p>고객의 부담을 줄이기 위해 인증 신청서
                    작성, 제출, 관련 문서 준비를 수행합니다</p>
                </div>
              </div>
            </div>
            <div className={`${isTablet ? "" : "w-1/3"} py-[30px]  px-8 flex flex-col justify-center items-center`}>
              <Pic23 size={`64px`} />
              <div className="mt-[30px]  mb-3 text-center">
                <div className={`${isMobile ? "text-lg-title-mobile" : "text-lg-title-desktop"} font-semibold  mb-3`}>인증 통과를 위한 전략 수립</div>
                <div className={`${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>
                  <p>고객의 제품 및 기술 특성에 맞는 인증 통과 전략을 제시합니다</p>
                </div>
              </div>
            </div>
          </div>
        </div >
      </div>
      {/* 네번째 section */}
      <div className={`bg-gradient-to-b from-transparent to-[#FAF8FF]`}>
        <div className="w-full max-w-6xl px-6 mx-auto mt-10 py-20">
          <p className={`text-center text-primary-purple ${isMobile ? "text-desc-tablet" : "text-desc-desktop"}  font-semibold`}>
            R&D 사업 선정 컨설팅
          </p>
          <p className={`text-center ${isMobile ? "text-lg-title-mobile" : "text-lg-title-desktop"}  font-semibold`}>
            성공적인 R&D 사업 선정을 위해 데이터 기반의 목표 성능 수치를 도출하고 <br />
            최적의 결과를 낼 수 있도록 솔루션을 제공합니다</p>
          <div id="boxWrap" className={`flex justify-evenly my-16  gap-8 ${isTablet ? " flex-col" : ""}`}>
            <div className={`${isTablet ? "" : "w-1/3"} py-[30px]  px-8 flex flex-col justify-center items-center`}>
              <Pic31 size={`64px`} />
              <div className="mt-[30px]  mb-3 text-center">
                <div className={`${isMobile ? "text-lg-title-mobile" : "text-lg-title-desktop"} font-semibold  mb-3`}>사업계획서 작성</div>
                <div className={`${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>
                  <p>사업 분석부터 실행 계획 수립까지, 고객의 요구에
                    맞춘 체계적인 솔루션을 제공합니다 </p>
                </div>
              </div>
            </div>
            <div className={`${isTablet ? "" : "w-1/3"}  py-[30px]  px-8 flex flex-col justify-center items-center`}>
              <Pic32 size={`64px`} />
              <div className="mt-[30px]  mb-3 text-center">
                <div className={`${isMobile ? "text-lg-title-mobile" : "text-lg-title-desktop"} font-semibold  mb-3`}>목표 성능 수치 수립 전략</div>
                <div className={`${isMobile ? "text-desc-tablet" : "text-desc-desktop"}`}>
                  <p>데이터 분석을 통해 실현 가능한 수치를 설정하고
                    심사 기준에 적합한 목표 성능 수치를 설정합니다</p>
                </div>
              </div>
            </div>
          </div>
        </div >
      </div >
      {/* 다섯번째 section */}
      {/* <div className={`${isMobile ? "w-4/5 py-10" : "w-3/5 py-20"} gap-2 mx-auto  mt-10`}>
        <p className={`${isMobile ? " text-xs" : "text-sm"} font-semibold px-4 py-2 w-fit block bg-black rounded-full text-white`}>
          R&D 기획
        </p>
        <p className={`${isMobile ? "text-lg-title-mobile" : "text-lg-title-desktop"}  font-semibold mt-2 mb-8`}>
          R&D 기획 컨설팅 프로세스</p>
        <div className={`border h-56 bg-[#D1D1D1]`} />
      </div > */}
    </>
  );
};
